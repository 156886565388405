import React, { useState, useCallback, useContext } from "react";
/* import logo from "../../assets/images/AI4R_logo.png"; */
import logo from "../../assets/images/calmatters.jpg";
import * as S from "./Header.Style";
import { UserContext } from '../../hooks/UserContext';
import useLogout from '../../hooks/useLogout';
import "./Header.css";
import { useHistory ,useLocation} from 'react-router-dom';


export default function Header() {
    let history = useHistory();
    const location = useLocation();
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleButton = () => setHamburgerOpen(!hamburgerOpen);
    const { user } = useContext(UserContext);
    const { logoutUser } = useLogout();
    function getFirstLetters(str) {
        if(str){
            const firstLetters = str
            .split(' ')
            .map(word => word[0])
            .join('');
        
          return firstLetters;
        }
        return "";
      }
    return (
         (location.pathname != '/login' && location.pathname != '/signup' && location.pathname != '/interests')  &&  <S.HeaderNav>
         <S.Brand onClick={function(){history.push('/')}}>
             <S.MenuLink>
                 <S.Logo src={logo} alt="AI TipSheets by CalMatters" />
             </S.MenuLink>

             <S.MenuLink >
                 <S.Name>AI Tip Sheets</S.Name>
             </S.MenuLink>
         </S.Brand>
         <S.BurgerMenu
             isActive={hamburgerOpen}
             onClick={toggleButton}
             barColor="black"
         />
         <S.Nav hamburgerOpen={hamburgerOpen}>
             {<S.EmptyDiv className="empty-div"></S.EmptyDiv>
             /* <S.MenuLink  onClick={function(){history.push('/interests')}}>INTERESTS</S.MenuLink> */}
             <S.MenuLink  onClick={function(){history.push('/about')}}>ABOUT</S.MenuLink>
             <S.MenuLink  onClick={function(){history.push('/archive')}}>ARCHIVE</S.MenuLink>
             { /* <S.MenuLink  onClick={function(){history.push('/contact')}}>CONTACT</S.MenuLink> */
             }
             <S.EmptyDiv className="empty-div"></S.EmptyDiv>
             {user ? <>
                <S.EmptyDiv className="empty-div"></S.EmptyDiv>
                 <div className="dropdown">
                     <button className="dropbtn">{(user.name)?getFirstLetters(user.name).slice(0,3):"👤"}</button>
                     <div className="dropdown-content">
                         <span className="caret up"></span>
                         <a style={{"borderBottom": "1px solid lightgray","text-align": "center","font-weight": "600","text-transform": "capitalize"}} href="#">{user.name}</a>
                         <a  href="#" onClick={function(){history.push('/profile')}}>Profile</a>
                         {/* temporarily removed by andrew chan */}
                         {/* <a style={{"border-bottom": "1px solid lightgray"}} href="#">Setting</a> */}
                         <a href="#" onClick={logoutUser}>Sign Out</a>
                     </div>
                 </div>
                 {/* <S.MenuLink onClick={logoutUser}>Logout </S.MenuLink> */}
             </>
                 : <>
                     <S.MenuLink className="lnkButton login" onClick={function(){history.push('/login')}}>Log In </S.MenuLink>
                     {/*<S.MenuLink className="lnkButton" onClick={function(){history.push('/signup')}}>Sign Up</S.MenuLink>*/}
                 </>
             }

         </S.Nav>
     </S.HeaderNav>
        
    );
}
