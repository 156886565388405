import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Archive from "./components/Archive/Archive";
import Staff from "./components/Staff/Staff";
import Contact from "./components/Contact/Contact";
import Modal from "react-modal";
import Story from "./components/Story/Story";
import Signup from "./components/Account/Signup/Signup";
import Interests from "./components/Account/Interests/Interests";
import Profile from "./components/Account/Profile/Profile";
import Login from "./components/Account/Login/Login";
import StoryRaw from "./components/StoryRaw/StoryRaw";
import StoriesRaw from "./components/StoriesRaw/StoriesRaw";
import PrivateRoute from './pages/PrivateRoute';
import  {UserContext}  from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import React, {useState} from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
Modal.setAppElement("#root");
//require('dotenv').config(); // dotenv should only be used in the backend
// Route is used to navigate to the separate pages such as Homepage, About, Staff, Article etc
function App() {
    // const location = useLocation();
    const {
        user,
        setUser,
        isLoading } = useFindUser();

    return (
        <GoogleOAuthProvider clientId="891898669780-734dqak2rq00s4ilock73mc60le86dlv.apps.googleusercontent.com">
            <Router>
                <UserContext.Provider value={{ user, setUser, isLoading }}>
                    <Header />
                    <Switch>
                        <PrivateRoute path="/" component={Home} exact />
                        <PrivateRoute
                            path="/about"
                            component={(props) => <About {...props} />}
                            exact
                        />
                        <PrivateRoute path="/archive" component={Archive}  />
                        <PrivateRoute path="/staff" component={Staff}  />
                        <PrivateRoute path="/contact" component={Contact}  />
                        <PrivateRoute path="/signup" component={Signup}  />
                        <PrivateRoute path="/interests" component={Interests}  />
                        <Route path="/login" component={Login}  />
                        <PrivateRoute path="/profile" component={Profile}  />
                        <PrivateRoute path="/util/:storyid" component={StoryRaw} />
                        <PrivateRoute path="/util" component={StoriesRaw} />
                        <PrivateRoute path="/:storyid" component={Story}  />
                    </Switch>
                </UserContext.Provider>
            </Router>
        </GoogleOAuthProvider>
    );
}
export default App;
