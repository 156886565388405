import React from "react";

export default function Contact(props) {
    // console.log(props);
    return (
        <div>
            <h2>This is Contact Page</h2>
        </div>
    );
}
