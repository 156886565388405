import React from "react";
import { Chart } from "react-google-charts";

export const data_orig = [
  [
    { type: "date", id: "Date" },
    { type: "number", id: "Won/Loss" },
  ],
  [new Date(2013, 2, 4), 10],
  [new Date(2013, 2, 5), 3],
  [new Date(2013, 2, 7), -1],
  [new Date(2013, 2, 8), 2],
  [new Date(2013, 2, 12), -1],
  [new Date(2013, 2, 13), 1],
  [new Date(2013, 2, 15), 1],
  [new Date(2013, 12, 16), -4],
  [new Date(2013, 1, 4), 10],
  [new Date(2013, 1, 5), 3],
  [new Date(2013, 1, 7), -1],
  [new Date(2013, 1, 8), 2],
  [new Date(2013, 1, 12), -1],
  [new Date(2014, 1, 13), 1],
  [new Date(2014, 1, 15), 1],
  [new Date(2014, 1, 16), -4],
];

export const options_orig = {
  title: "Red Sox Attendance",
  calendar: { cellSize: 10 },
};

export default function Calendar(props) {
  return (
    <Chart
      chartType="Calendar"
      width="100%"
      height="100%"
      data={props.data}
      options={props.options}
    />
  );
}