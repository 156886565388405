import { useState } from 'react';  

export default function useForm({ initialValues }) {
    const [values, setValues] = useState(initialValues || {});

    const handleChange = event => {
        const value = event.target.value; 
        const name = event.target.name;

        setValues({
            ...values,
            [name]: value
        }); 
        console.log(values);
    };
    const reset = ()=>{
        setValues(initialValues);
    }
    return {
        handleChange,
        values,
        reset
    }
}