import React, { useRef, useEffect, useState } from "react";
import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";
import "../Story/Story.Style";
import "./TipSheet.css";
import axios from "axios";
import ChartPie from "../Chart/ChartPie";

// This component is making an API call that should be unnecessary, since
// Story.js can pass the required JSON data. The component is also not generating
// any visible elements.
export default function TipSheet_Participant(props) {
    const [dataTopic, setDataTopic] = useState([]);
    // const [dataAll, setDataAll] = useState([]);
    // const [dataTips, setDataTips] = useState([]);

    const fetchData = () => {
        // console.log(props.api_url);

        axios
            .get(props.api_url)
            .then((response) => {
                // console.log(response.data.tipsheet_html_segments);
                // console.log(response.data);
                setDataTopic(response.data.tipsheet_html_segments);
                // setDataAll(response.data);

                // setDataTips(response.data);
            })
            .catch((err) => console.log(err));
    };

    function Sub_Index(item) {
        var subject_index = item.map((element) =>
            element.indexOf("Speaker Participation Chart (seconds)")
        );
        var subject_index_copy = subject_index;
        var subject_index_num = subject_index_copy.filter((item) => item >= 0);
        subject_index_num = subject_index.indexOf(subject_index_num[0]);
        // console.log(subject_index_num);
        return subject_index_num;
    }
   /* useEffect(() => {
        fetchData();
    }, [fetchData]); */

    useEffect(() => {
        fetchData();
        // let dataTopic1 = dataTopic.tipsheet_html_segments;
        let temporary = document.createElement("div");
        let wrap = document.querySelector(".attendance_participation");
        // temporary.innerHTML = dataTopic[Sub_Index(dataTopic)];
        // console.log(dataTopic);
        temporary.innerHTML =
            dataTopic[Sub_Index(dataTopic)] === undefined
                ? null
                : dataTopic[Sub_Index(dataTopic)];
        temporary.classList = " outline";
        wrap.appendChild(temporary);
    }, []);

    return <div className="attendance_participation"></div>;
}
