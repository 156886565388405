import React from "react";
import * as S from "./Staff.Style";
import Staff_Data from "../../assets/Staff_Data";

export default function Staff() {
    return (
        <>
            <S.PageTitle>STAFF</S.PageTitle>
            <S.StaffWrap>
                {Staff_Data.map((item, i) => {
                    return (
                        <S.StaffCardContainer key={i}>
                            <S.Textrap>
                                <S.Name>{item.name}</S.Name>
                                <S.Title>{item.title}</S.Title>
                                <S.University>{item.University}</S.University>
                            </S.Textrap>
                            <S.ImageDescWrap>
                                <S.Photo src={item.Photo} />
                                <S.Description>
                                    {item.Description}
                                </S.Description>
                            </S.ImageDescWrap>
                        </S.StaffCardContainer>
                    );
                })}
            </S.StaffWrap>
        </>
    );
}
