import React, { useState, useEffect ,useRef} from "react";
import * as S from "../Profile.Style";
import lawMaker from '../../../../hooks/lawMaker'; 
import Select from 'react-select'
    

export default function LawMakerComponent(props) {
    const { Lawmakers, setLawmakers } = props;
    const selectInputRef = useRef();

    const { getLawmakersList } = lawMaker();
    const [getLawmakerListState, setLawmakerListState] = useState([]);
    const [lawmakeroptions, setLawmakerOptions] = useState([]);
    const [getRemoveLawmakerTag, setRemoveLawmakerTag] = useState(-1);
    const [getLawmakerId, setLawmakerId] = useState(null);

    useEffect(() => {
        async function fetchLawmakers() {
            let lawmakersList = await getLawmakersList();
            setLawmakerListState(lawmakersList);
        }
       
        fetchLawmakers();
    },[])

    useEffect(() => {
        async function mapLawmakers() {
            let options = []
            getLawmakerListState.map((lawmaker, i) => {
                options.push({
                    value: lawmaker.pid , label: lawmaker.first + " " + lawmaker.last + " (" + lawmaker.party[0] + ")"
                })
            })
            setLawmakerOptions(options);
        }
        
        mapLawmakers();
    }, [getLawmakerListState])

    

    useEffect(() => {
        if (getRemoveLawmakerTag !== -1) {
            setLawmakers(Lawmakers.filter(e => e.pid !== getRemoveLawmakerTag));
            setRemoveLawmakerTag(-1);
        }
    }, [getRemoveLawmakerTag]);

    const style1 = {
        control: (base, state) => ({
            ...base,
            border: "1px solid #ced4da !important",
            boxShadow: "1px solid #ced4da !important",
            "&:hover": {
                border: "1px solid #ced4da !important",
            }
        }),
        indicatorsContainer: (prevStyle, state) => state.isMulti ? ({
            ...prevStyle,
            display: 'none'
        }) : null,
    };

    const handleLawMakerCheckboxClick = (lawmaker) => {
        let exist = false;
        Lawmakers.forEach(tag => {
            if (lawmaker.pid === tag.pid) exist = true;
        });
        if (!exist) {
            const obj = {
                pid: lawmaker.pid,
                first: lawmaker.first,
                last: lawmaker.last,
                party: lawmaker.party
            }
            const listlaw = [...Lawmakers, obj];
            setLawmakers(listlaw);
        }
        else {
            setRemoveLawmakerTag(lawmaker.pid);
        }
    };

    const lawmakerSelected = (lawmaker) => {
        let selected = false;
        Lawmakers.forEach(tag => {
            if (lawmaker.pid === tag.pid) selected = true;
        });
        return selected ;
    }

    const AddSelectedLawmaker = ()=>{
        if (!getLawmakerId) return
        let exist = false;
        Lawmakers.forEach(tag => {
            if (getLawmakerId.value === tag.pid) exist = true;
        });
        if (!exist){
            getLawmakerListState.forEach(lawmaker => {
                if (getLawmakerId.value === lawmaker.pid) {
                    const obj = {
                        pid: lawmaker.pid,
                        first: lawmaker.first,
                        last: lawmaker.last,
                        party: lawmaker.party
                    }
                    const listlaw = [...Lawmakers, obj];
                    setLawmakers(listlaw);
                }
            });
        }
        setLawmakerId(null);
    }
    return (
        <S.BodyBox className="BodyBox">
        <div class="lawmaker-container">
            <div class="lawmaker-left">
                <S.BodyBoxTitle className="BodyBoxTitle">ADD LAWMAKERS</S.BodyBoxTitle>
                <div className="lawmaker-form">
                    <div className="lawmaker-form-group">
                        <label className="lawmaker-label">Search Lawmaker</label> 
                            <Select 
                                ref={selectInputRef}
                                value={getLawmakerId}
                                options={lawmakeroptions}  
                                styles={style1} 
                                placeholder="Search by name" 
                                onChange={setLawmakerId}
                                isClearable={true}

                            />
                    </div>
                    <div style={{
                        position: "relative",
                        height: "100%"
                    }}>
                        <button className="lawmaker-button" onClick={function () { AddSelectedLawmaker(); }}>Add</button>
                    </div>
                </div>
                <div className="lookup-lawmaker">
                    <label className="lawmaker-label-or">or</label>
                    <S.BodyBoxTitle className="BodyBoxTitle">SELECT LAWMAKERS</S.BodyBoxTitle>
                </div>
                <div className="lawmaker-checkbox-container">
                    <div className="lawmaker-chamber-div">
                        <div className="lawmaker-label-or">Assembly</div>
                        <div className="lawmaker-checkbox">
                            {getLawmakerListState &&
                                getLawmakerListState.map((lawmaker, i) => {
                                    if (lawmaker.chamber === "Assembly") {
                                        return <label class="check-container">{lawmaker.first} {lawmaker.last} ({lawmaker.party[0]})
                                            <input type="checkbox"  
                                                checked={lawmakerSelected(lawmaker)}
                                                onChange={() => {
                                                    handleLawMakerCheckboxClick(lawmaker);
                                                }}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    }
                                    return null;
                                })
                            }
                        </div>
                    </div>
                    <div className="lawmaker-chamber-div">
                        <div className="lawmaker-label-or">Senate</div>
                        <div className="lawmaker-checkbox">
                            {getLawmakerListState &&
                                getLawmakerListState.map((lawmaker, i) => {
                                    if (lawmaker.chamber === "Senate") {
                                        return <label class="check-container">{lawmaker.first} {lawmaker.last} ({lawmaker.party[0]})
                                            <input type="checkbox"  
                                                checked={lawmakerSelected(lawmaker)}
                                                onChange={() => {
                                                    handleLawMakerCheckboxClick(lawmaker);
                                                }}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    }
                                    return null;
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="lawmaker-right">
                <S.BodyBoxTitle className="BodyBoxTitle">LAWMAKERS ADDED</S.BodyBoxTitle>
                {Lawmakers.map((tag, i) => {
                    return <S.TagItem className="lawmakerTag">
                        <span class="tag-remove" onClick={function () { setRemoveLawmakerTag(tag.pid) }}></span>
                        <span className="tag-content">{tag.first} {tag.last} ({tag.party[0]})</span>
                    </S.TagItem>
                    })
                }
            </div>

        </div>
     
    </S.BodyBox>

    );

}