import logo from "../../../assets/images/AI4R_logo.png";
import background from "../../../assets/images/background.jpg";

import * as S from "../Account.Style";
import "../Account.css";
import Error from '../../../hooks/Error';
import useForm from '../../../hooks/useForm';
import useAuth from '../../../hooks/useAuth';
import { useHistory} from 'react-router-dom';

export default function Signup(props) {
    let history = useHistory();
    const { values, handleChange } = useForm({
        initialValues: {
            email: '',
        }
    });
    const { registerUser, error } = useAuth();

    const handleRegister = async (e) => {
        e.preventDefault();
        if (validate()) {
            await registerUser(values);
        }

    }
    const validate = () => {
        let isValid = true;
        let input = values;
        document.getElementById('form-error').innerHTML = "";
        document.getElementById('message-email').innerHTML = "";

        if (!input["email"]) {
            isValid = false;
            document.getElementById('message-email').innerHTML = "Please enter your email Address.";
        }
        if (input["email"]) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                document.getElementById('message-email').innerHTML = "Please enter valid email address.";
            }
        }
        return isValid;
    }
    return (
        <div className="backgroundDiv" style={{ backgroundImage: `url(${background})` }}>
            <div className="account-brand">
                <S.Brand onClick={function(){history.push('/')}}>
                    <S.MenuLink to="#">
                        <S.Logo src={logo} alt="AI4Reporters" />
                    </S.MenuLink>

                    <S.MenuLink to="#">
                        <S.Name>AI 4 REPORTERS</S.Name>
                    </S.MenuLink>
                </S.Brand>
            </div>
            <div style={{ display: "flex" }}>
                <div className="account-container">
                    <h3>Sign Up</h3>
                    <form className="account-form" onSubmit={handleRegister}>
                        <div className="account-message" id="form-error">
                            {/* {error && <Error error={error}/>} */}
                        </div>
                        <div className="account-form-group">
                            <label className="account-label" >Email</label>
                            <input
                                placeholder={"Email"}
                                name="email"
                                type="text"
                                className="account-form-control"
                                value={values.email}
                                onChange={handleChange} />
                            <label id="message-email" class="account-message" ></label>
                        </div>
                        <button className="account-button" type="submit">Sign Up</button>
                        <div className="account-bottom">Already have an account?   <a class="account-anchor"
                            href="#"  onClick={function(){history.push('/login')}}>SIGN IN</a></div>
                    </form>
                </div>
            </div>

        </div>
    );
}
