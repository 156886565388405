import styled from "styled-components";
export const BodyHeadingDiv = styled.div`
    font-size:12px;
    font-weight:500;
`;
export const LeftHeadingDiv = styled.div`
    font-size:16px;
    font-weight:bolder;
    padding: 20px 20px;
`;
export const LeftOptionDiv = styled.div`
    font-size:14px;
    font-weight:700;
    border-top:1px solid #E5E5E5;
    padding: 15px 20px;
    color:#C6C6C6;
    cursor:pointer;
    display: grid;
    grid-template-columns: 25px auto;
`;
export const ConatinerDiv = styled.div`
    min-width: 400px;
    min-height:400px;
    max-width:1280px;
    margin: 20px auto 0 auto;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap:10px;
    @media (max-width: 1080px) {
        max-width:1080px;
    }
    @media (max-width: 768px) {
        max-width:768px;
    }
`;
export const LeftSidebarDiv = styled.div`
    padding: 0 5px;
`;
export const LeftSidebar = styled.div`
    background: white;
    border-radius: 8px;
    
`;
export const Body = styled.div`
    padding: 0 5px;
`;
export const BodyBox = styled.div`
    background: White;
    border-radius: 8px;
    min-height:150px;
    padding: 15px 15px 15px 15px;
    margin-bottom:15px;
`;
export const BodyBoxTitle = styled.div`
    font-size:13px;
    font-weight:600;
    margin-bottom: 10px;
`;
export const FilterBoxes = styled.div`
    display: grid;
    grid-template-rows: repeat(10, 30px);
    grid-template-columns: 150px 150px 150px;
    grid-gap: 5px;
`;
export const MenuItem = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 12px 125px;
    grid-gap: 4px;
`;
export const Tags = styled.span`
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
`;

export const TagDiv = styled.div`
    width:100%;
`;
export const TagItem = styled.div`
    display: inline-block;
    margin: 5px;
    border: 1px solid gray;
    border-radius: 6px;
    font-size: 12px;
`;

export const LawmakerItem = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 12px 125px;
    grid-gap: 4px;
`;
export const LawmakerName = styled.span`
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    margin-left: 10px;
`;