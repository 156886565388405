import axios from 'axios';

export default function lawMaker() {
    const getLawmakersList = async () => {
        let axiosT = axios.create({
            baseURL: `${process.env.REACT_APP_FEATURED_API}lawmaker/list`,
        });

        return axiosT.get('')
        .then(async (res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
        })
    };

    return {
        getLawmakersList
    }
}
