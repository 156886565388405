import { useContext } from 'react'; 
import { useHistory } from 'react-router-dom';
import { UserContext } from './../hooks/UserContext';
export default function useLogout() {
    let history = useHistory();
    const {setUser} = useContext(UserContext);
    const logoutUser = async () => {
        try {
            setUser(null);  
            localStorage.removeItem('token');
            history.push('/login');
        } catch(err) {
            console.log(err);
        } 
    }

    return {
        logoutUser
    }

}