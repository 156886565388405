import ModalUI from "../../UI/Modal.js";
import * as S from "./Story.Style.js";
import Carousel from "react-multi-carousel";
import {
  createTranscriptHTML,
  createTranscriptHTMLQuote,
  responsive,
} from "./StoryUtil.js";
import { useEffect, useRef, useState } from "react";

/*
MODES: Either storyData or quoteData

*** Full Story (storyData) ***
Minimum Required Fields for storyData:
storyData = {
  video: [string]; 
  hearing_transcript: [transcript_chunk]; (whole transcript from story)
};


*** Quote (quoteData) ***
Minimum Required Fields for quoteData:
quoteData = {
  vid_file_id: string;
  start_time: number;  
  transcript: [transcript_chunk]; (whole transcript from story)
};

***

transcript_chunk = {
  end_time: number;
  start_time: number;
  speaker_first: string;
  speaker_last: string;
  utterance: string;
  vid_file_id: string;
  ...
}
*/

export default function VideoTranscriptModal({
  storyData,
  quoteData,
  children,
}) {
  const modalVidSetOpenClose = () => setModalVidIsOpen(!modalVidIsOpen);
  const [modalVidIsOpen, setModalVidIsOpen] = useState(false);
  const [transcriptText, setTranscriptText] = useState(false);
  const [modalVidIndex, setModalVidIndex] = useState(0);
  const [isUserInteracting, setIsUserInteracting] = useState(false);

  const vidPlayer = useRef(null);
  const transcriptRef = useRef(null);

  const play = (index) => {
    setModalVidIndex(index);
    setModalVidIsOpen(true);
  };

  // Function to scroll to the current cue
  const scrollToCurrentCue = () => {
    if (!isUserInteracting) {
      const currentCue = document.querySelector(".cue.current");
      if (currentCue && transcriptRef.current) {
        transcriptRef.current.scroll(0, currentCue.offsetTop);
      }
    }
  };

  let lastCurrentCue;
  function setCurrentCue(currentTime) {
    var cues = document.getElementsByClassName("cue");
    var newCurrentCue;

    for (var i = 0; i < cues.length; i++) {
      var start = parseFloat(cues[i].getAttribute("data-starttime"));
      var end = parseFloat(cues[i].getAttribute("data-endtime"));

      if (parseFloat(currentTime) >= start && parseFloat(currentTime) <= end) {
        newCurrentCue = cues[i];
        break;
      }
    }

    if (newCurrentCue) {
      if (newCurrentCue !== lastCurrentCue) {
        lastCurrentCue && lastCurrentCue.classList.remove("current");
        newCurrentCue.classList.add("current");
        lastCurrentCue = newCurrentCue;

        if (!isUserInteracting) {
          scrollToCurrentCue();
        }
      }
    } else {
      lastCurrentCue && lastCurrentCue.classList.remove("current");
      lastCurrentCue = undefined;
    }
  }

  function timeupdate(evt) {
    if (!vidPlayer.current) return;
    if (vidPlayer.current.paused || vidPlayer.current.ended) return;
    setCurrentCue(vidPlayer.current.currentTime);
  }

  function playat(evt) {
    if (!vidPlayer.current) return;
    vidPlayer.current.currentTime = parseFloat(
      this.getAttribute("data-starttime")
    );
    vidPlayer.current.play();
    setCurrentCue(vidPlayer.current.currentTime);
  }

  // Function to reset the snap back feature
  const resetSnapBack = () => {
    clearTimeout(snapBackTimeout.current); // Clear any existing timeout
    snapBackTimeout.current = setTimeout(() => {
      setIsUserInteracting(false); // Set to false after timeout
      scrollToCurrentCue();
    }, 10000); // Set a new timeout
  };

  // Function to set user interaction
  const setUserInteraction = () => {
    setIsUserInteracting(true);
    resetSnapBack(); // Reset the snap back timer on user interaction
  };

  // Outside your component, declare a variable for the timeout ID
  // let snapBackTimeout;
  let snapBackTimeout = useRef(null);

  useEffect(() => {
    if (modalVidIsOpen) {
      let selectedVideo = null;
      if (quoteData && quoteData.vid_file_id) {
        selectedVideo = `https://videostorage-us-west.s3-us-west-2.amazonaws.com/videos/${quoteData.vid_file_id}/${quoteData.vid_file_id}.mp4#t=${quoteData.start_time}`;
      } else if (storyData) {
        selectedVideo = storyData.video[modalVidIndex];
      }
      if (!selectedVideo) {
        console.error("Video not found");
        return;
      }

      vidPlayer.current.src = selectedVideo;
      vidPlayer.current.load();
      vidPlayer.current.addEventListener("timeupdate", timeupdate);

      // transcript text
      let transcriptHTML = "";
      if (quoteData && quoteData.vid_file_id) {
        transcriptHTML = createTranscriptHTMLQuote(quoteData);
      } else {
        transcriptHTML = createTranscriptHTML(storyData, modalVidIndex);
      }
      if (transcriptHTML) {
        setTranscriptText(transcriptHTML);

        // Wait for the next render cycle after setting transcript text
        setTimeout(() => {
          var cues = document.getElementsByClassName("cue");
          for (var i = 0; i < cues.length; i++) {
            cues[i].addEventListener("click", playat, false);
            //console.log('Event listener added to:', cues[i]);
          }
        }, 0);
      }

      const transcriptArea = transcriptRef.current;
      transcriptArea.addEventListener("scroll", setUserInteraction);
      transcriptArea.addEventListener("mousemove", setUserInteraction);

      resetSnapBack(); // Initialize the snap back feature

      return () => {
        clearTimeout(snapBackTimeout.current);
        transcriptArea.removeEventListener("scroll", setUserInteraction);
        transcriptArea.removeEventListener("mousemove", setUserInteraction);

        vidPlayer.current?.removeEventListener("timeupdate", timeupdate);
        var cues = document.getElementsByClassName("cue");
        for (var i = 0; i < cues.length; i++) {
          cues[i].removeEventListener("click", playat);
        }
      };
    }
  }, [modalVidIsOpen, modalVidIndex, storyData?.video, quoteData?.vid_file_id]);

  // only render modal & trigger if have necessary data
  let validQuoteData = quoteData && quoteData.vid_file_id && quoteData.start_time && quoteData.transcript;
  let validStoryData = storyData && storyData.video && storyData.hearing_transcript
  if (!validQuoteData && !validStoryData) {
    return <></>
  }

  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setModalVidIsOpen(true)}
      >
        {children ?? "Modal Trigger"}
      </span>

      <ModalUI
        style={{
          height: "auto",
          //maxHeight: "80%",
          borderRadius: "5px",
          overflow: "hidden",
          padding: "10px",
          //maxWidth: "600px",
          backgroundColor: "white",
        }}
        showModal={modalVidIsOpen}
        onClose={modalVidSetOpenClose}
      >
        <S.VideoPlayer
          key="vid{modalVidIndex}"
          ref={vidPlayer}
          width={"100%"}
          id="videoP"
          controls
        />
        <S.Block>
          <Carousel responsive={responsive}>
            {!quoteData &&
              storyData.video?.map((video, i) => {
                return (
                  <S.VideoBlock>
                    <S.VideoPlayer
                      width={"100%"}
                      src={video}
                      onClick={() => {
                        play(i);
                      }}
                    />
                  </S.VideoBlock>
                );
              })}
          </Carousel>
        </S.Block>
        <S.TranscriptBox
          ref={transcriptRef}
          id="transcript"
          dangerouslySetInnerHTML={{ __html: transcriptText }}
        ></S.TranscriptBox>
      </ModalUI>
    </>
  );
}
