import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useHistory, useParams } from "react-router-dom";

export default function StoryRaw(props) {
    const { storyid } = useParams();
    const [error, setError] = useState(null);
    let location = useLocation();
    const history = useHistory();

    //new function by Thomas G. to be backwards compatible with old style naming.
    function check_story_id(id) {
        let tipsheet_id_fields = id.split("_")
        if (tipsheet_id_fields.length > 4) {
            return tipsheet_id_fields[0].concat(
                "_", tipsheet_id_fields[1], "_", tipsheet_id_fields[3], "_", tipsheet_id_fields[4],
            )
        } else {
            return id
        }
    }

    const handleExpiredToken = () => {
        console.log("Expired session token, redirecting to login.")
        localStorage.removeItem('token');
        history.push({
            pathname: '/login',
            state: { from: location }
        });
    };

    const handleOpenJson = async () => {
        // build route from path variable (:story)
        let APIPathStory = check_story_id(storyid);
        let API_URL = `${process.env.REACT_APP_FEATURED_API}` + `${APIPathStory}`;
        let axiosT = axios.create({
            baseURL: `${API_URL}`,
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        });
        axiosT
            .get("/")
            .then((response) => {
                if (response.status !== 200) {
                    const errorDetails = {
                        status: response.status,
                    };
                    console.log("error error: ", response.status);
                    throw errorDetails;
                }
                const formattedJson = JSON.stringify(response.data);
                const blob = new Blob([formattedJson], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            })
            .catch((err) => {
                if (err.status === 401) {
                    handleExpiredToken();
                }
                setError(err.status);
            })
    };

    if (error) return <div>API error, status {error}</div>;

    return (
        <div>
            <button onClick={handleOpenJson}>View JSON Data</button>
        </div>
    );
}
