export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

var toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const createTranscriptHTML = (story, modalVidIndex) => {
  let transcriptHTML = `<table style="width:100%;" cellspacing=0 cellpadding=0>
        <thead>
          <tr>
            <th style="width:20%">Speaker</th>
            <th style="width:60%">Content</th>
            <th style="width:20%">Time Stamp</th>
          </tr>
        </thead>
        <tbody>
      `;

  story.hearing_transcript
    ?.filter(
      (transcriptObj) =>
        transcriptObj.vid_file_id ===
        story.video[modalVidIndex].split("/").reverse()[1]
    )
    .map((transcriptObj, i) => {
      transcriptHTML += `<tr id="c${i}" class="cue" data-starttime="${
        transcriptObj.start_time
      }" data-endtime="${transcriptObj.end_time}" tabindex="1">
          <td>${transcriptObj.speaker_first} ${transcriptObj.speaker_last}</td>
          <td>${transcriptObj.utterance}</td>
          <td>${toHHMMSS(transcriptObj.start_time)}-${toHHMMSS(
        transcriptObj.end_time
      )}</td>
        </tr>`;
    });
  transcriptHTML += ` </tbody>
                        </table>`;

  return transcriptHTML;
};

export const createTranscriptHTMLQuote = (quoteData) => {
  console.log("transcript", quoteData);
  let transcriptHTML = `<table style="width:100%;" cellspacing=0 cellpadding=0>
          <thead>
            <tr>
              <th style="width:20%">Speaker</th>
              <th style="width:60%">Content</th>
              <th style="width:20%">Time Stamp</th>
            </tr>
          </thead>
          <tbody>
        `;

  quoteData.transcript
    ?.filter(
      (transcriptObj) => transcriptObj.vid_file_id === quoteData.vid_file_id
    )
    .map((transcriptObj, i) => {
      transcriptHTML += `<tr id="c${i}" class="cue" data-starttime="${
        transcriptObj.start_time
      }" data-endtime="${transcriptObj.end_time}" tabindex="1">
            <td>${transcriptObj.speaker_first} ${
        transcriptObj.speaker_last
      }</td>
            <td>${transcriptObj.utterance}</td>
            <td>${toHHMMSS(transcriptObj.start_time)}-${toHHMMSS(
        transcriptObj.end_time
      )}</td>
          </tr>`;
    });
  transcriptHTML += ` </tbody>
                          </table>`;

  return transcriptHTML;
};
