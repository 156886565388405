import styled from "styled-components";

export const SideBar = styled.div`
    /* grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end | itemname; */
    display: flex;
    flex-direction: column;
    background-color: #0e4c60; //#4c4b4c;
    border-color: black;
    font-size: 25px;
    grid-area: a;
    justify-self: stretch;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.2);
    /* height: auto; */
    height: 100%;
`;

export const SBIcons = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    margin-top: 30px;
    grid-gap: 25px;
    justify-content: center;
    align-items: center;
    width: 100%;
    color:white;
    font-size:10px;
    text-align:center;
`;
export const SideBarIcon1 = styled.img`
    width: 40px;
    cursor: pointer;
    padding-inline: 23px;

    border-right: ${(props) => (props.mark ? "3px solid #80b5e2" : "")};
    z-index: ${(props) => (props.mark ? 11 : "")};
`;

export const SideBarIcon2 = styled.img`
    width: 40px;
    cursor: pointer;
    padding-inline: 23px;

    border-right: ${(props) => (props.mark ? "3px solid #80b5e2" : "")};
    z-index: ${(props) => (props.mark ? 11 : "")};
`;
export const SideBarIcon3 = styled.a`
    width: 40px;
    cursor: pointer;
    padding-inline: 23px;
    color:white;
    font-size:10px;
    text-align:center;
    border-right: ${(props) => (props.mark ? "3px solid #80b5e2" : "")};
    z-index: ${(props) => (props.modalArticleIsOpen ? 11 : "")};
`;

export const SideBarContainer = styled.div`
  color:white;
  font-size:10px;
  text-align:center;
`;
export const Image = styled.img`
    width: 42px;
    cursor: pointer;
`;

export const ClipboardWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;
export const ClipboardButton = styled.button`
    background: #4c4b4c;
    color: white;
    border: solid 1px white;
    border-radius: 8px;
    height: 33px;
    width: 160px;
    font-size: 14px;
    justify-self: center;
    align-self: center;
    cursor: pointer;

    &:hover {
        background-color: darkgray;
        transform: scale(1.2);
    }
`;

export const Cancel = styled.i`
    position: absolute;
    top: 30px;
    right: 30px;
`;
