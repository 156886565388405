import * as S from "./Filter.Styled";
import DateRangePicker from "../DateRange/DateRange.js";
import sortImg from "../../assets/sorticon.svg";

export default function ArchiveSearch({
  searchTerm,
  handleInputChange,
  handleSubmit,
  handleClear,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  sortBy,
  setSortBy,
}) {
  return (
    <>
      <S.FilterWrap
        className="archive-search"
        style={{ display: "flex", gap: "1rem", padding: ".5em 4em" }}
      >
        <S.SmWrap>
          <S.FilterTextBox
            placeholder="bills, ids"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleSubmit}
          />
        </S.SmWrap>
        <DateRangePicker
          onDateChange={() => {}}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <S.ClearButton onClick={handleClear}>Clear</S.ClearButton>
        {/*<S.SortButtons style={{marginLeft: "auto"}}>
          <img src={sortImg} height="30px" alt="sort by" />
          <S.ToggleButton
            active={sortBy === "title"}
            onClick={() => setSortBy("title")}
          >
            Title
          </S.ToggleButton>
          <S.ToggleButton
            active={sortBy === "chrono"}
            onClick={() => setSortBy("chrono")}
          >
            Date
          </S.ToggleButton>
        </S.SortButtons>*/}
      </S.FilterWrap>
    </>
  );
}
