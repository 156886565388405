import styled from "styled-components";
import { Link } from "react-router-dom";
export const Conatiner = styled.div`
    min-width: 370px;
    width: 790px;
    min-height: 400px;
    background: rgb(255, 255, 255);
    text-align: center;
    margin: 10px auto 0 auto;
    border-radius: 8px;
    padding-bottom: 10px;
`;
export const Brand = styled.div`
    display: flex;
    width: 100%;
    color: #4c4b4c;
    grid-template-columns: 60px 1fr;
    align-items: center;
    justify-content: center;
    grid-area: brand;
`;
export const Logo = styled.img`
    display: grid;
    max-width: 50px;
    justify-self: center;
    margin-left: 20px;
`;
export const MenuLink = styled(Link)`
    text-decoration: none;
    color: #4c4b4c;
`;
export const Name = styled.h2`
    font-family: roboto; //pt-serif;
    font-weight: 700;
    font-size: 32px;
    text-align: left;
    color: #4c4b4c;
    padding-left: 20px;
`;

export const FilterBoxes = styled.div`
    display: grid;
    grid-template-rows: repeat(10, 35px);
    grid-template-columns: 150px 150px 150px;
    // grid-gap: 5px;
    border-right: 1px solid #E4E7E9;
    padding-top:30px;
`;

export const FilterDiv = styled.div`
    border: 1px solid white;
    background-color: white;
    display: grid;
    grid-gap: 10px;
    padding: 0 0 0 65px;
    border-radius: 5px;
    display: grid;
    grid-template-areas: "col1 col2";
    margin-bottom:40px;
`;

export const MenuItem = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 143px;
    #grid-gap: 4px;
    height:17px;
`;
export const StateTags = styled.div`
    display: grid;
    grid-template-rows: repeat(8, 35px);
    grid-template-columns: 150px;
    // grid-gap: 5px;
    padding-top:30px;
`;
export const StateTagsWrap = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 143px;
    // grid-gap: 4px;
    padding-left: 40px
`;

export const Tags = styled.span`
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
`;