import styled from "styled-components";
import { Link } from "react-router-dom";
export const HeaderNav = styled.header`
    display: grid;
    width: 100vw;
    min-width: 100%;
    background-color: #f4f4f4;
    /* position: fixed; */
    /* top: 0;
    left: 0; */
    box-sizing: border-box;
    grid-template-areas: "brand nav";
    grid-template-columns: 310px auto;
    justify-items: center;
    align-content: center;
    @media (max-width: 800px) {
        padding: 10px 5px 5px 5px;
        grid-template-areas:
            "brand menu"
            "nav nav";
    }
`;

export const Brand = styled.div`
    display: grid;
    width: 100%;
    color: #4c4b4c;
    grid-template-columns: 60px 1fr;
    align-items: center;
    justify-content: center;
    grid-area: brand;
`;

export const Logo = styled.img`
    display: grid;
    width: 75%;
    justify-self: center;
    margin-left: 20px;
    border-radius: 10px;
`;

export const Name = styled.h2`
    font-family: roboto; //pt-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    color: black; //#4c4b4c;
    padding-left: 20px;
    &:hover {
        transform: scale(1.02);
    }
`;

export const Nav = styled.nav`
    display: grid;
    width: 100%;

    grid-template-columns: auto 180px 180px 180px auto 110px 110px 40px;
    justify-items: center;
    align-items: center;
    grid-area: nav;
    font-size: 20px;
    font-family: roboto;
    font-weight: 600;
    color: black;

    @media (max-width: 800px) {
        height: ${(props) => (props.hamburgerOpen ? "175px" : "0px")};
        overflow: hidden;
        transition: height ease-in-out 0.6s;
        grid-template-columns: none;
        grid-template-rows: repeat(3, 35px);
    }
`;

export const MenuLink = styled(Link)`
    text-decoration: none;
    color: black; //#4c4b4c;

    &.lnkButton {
        color: white !important;
        background-color: #0e4c60; // #4c4b4c;
        padding:5px 25px;
        font-weight:400;
        font-size:16px;
        border-radius:5px;
    }
    
    &.lnkButton.login{
        background-color:#a5a5a5;
    }
`;

export const EmptyDiv = styled.div`
    display: block;

    @media (max-width: 800px) {
        display: none;
    }
`;

export const BurgerMenu = styled.div`
    display: none;
    outline: none;
    grid-area: menu;

    @media (max-width: 800px) {
        display: block;
        justify-self: end;
    }
`;
