import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";
import axios from "axios";

// const ExampleChart = (props) => {
//     // const store = __html.assets[0].data;
//     const [dataAssets, setDataAssets] = useState([]);

//     const fetchData = () => {
//         // console.log(props.api_url);

//         axios
//             .get(props.api_url)
//             .then((response) => {
//                 // console.log(response.data.tipsheet_html_segments);
//                 // console.log(response.data);
//                 // console.log(123);
//                 setDataAssets(response.data);
//             })
//             .catch((err) => console.log(err));
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);
//     useEffect(() => {
//         setDataAssets(dataAssets);
//     }, [dataAssets]);

//     // console.log(props);
//     const Values = Object.values(dataAssets.assets.data);
//     //const Values = Object.values(__html.assets[0].data);

//     // props.data2.assets.map((item) => isPie.push(item.asset_type));

//     // let Values = dataAssets.assets.map((item) => {
//     //     return item.asset_type == "pie_chart" ? Object.values(item.data) : null;
//     // });
//     // const Values = props.pieData
//     //     ? Object.values(props.pieData)
//     //     : Object.values(__html.assets[0].data);
//     Values.sort((a, b) => {
//         return b.time - a.time;
//     });
//     // console.log(Values);

//     const timeArray = [];

//     Values.forEach((item) => {
//         timeArray.push(item.time);
//     });

//     var sumTotal = timeArray.reduce(function (a, b) {
//         return a + b;
//     }, 0);

//     const shortList = Values.slice(0, 11);
//     var sumShort = [];
//     shortList.forEach((item) => {
//         sumShort.push(item.time);
//     });

//     sumShort = sumShort.reduce(function (a, b) {
//         return a + b;
//     }, 0);

//     var othersTime = sumTotal - sumShort;

//     return (
//         <>
//             <Chart
//                 width={"700px"}
//                 height={"350px"}
//                 chartType="PieChart"
//                 loader={<div>Loading Chart</div>}
//                 data={[
//                     ["Name", "Time"],
//                     [shortList[0].name, shortList[0].time],
//                     [shortList[1].name, shortList[1].time],
//                     [shortList[2].name, shortList[2].time],
//                     [shortList[3].name, shortList[3].time],
//                     [shortList[4].name, shortList[4].time],
//                     [shortList[5].name, shortList[5].time],
//                     [shortList[6].name, shortList[6].time],
//                     [shortList[7].name, shortList[7].time],
//                     [shortList[8].name, shortList[8].time],
//                     [shortList[9].name, shortList[9].time],
//                     ["Other Speakers", othersTime],
//                 ]}
//                 options={{
//                     // title: __html.assets[0].caption,
//                     chartArea: {
//                         height: "95%",
//                         top: "0%",
//                         width: "95%",
//                         left: "25%",
//                     },
//                 }}
//                 rootProps={{ "data-testid": "1" }}
//             />
//         </>
//     );
// };
// export default ExampleChart;

const ChartPie = (props) => {
  return (
    <div className="Chart-container">
      <Chart
        width={"auto"}
        height={"auto"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={props.dataPie}
        options={{
        //   title: __html.assets[0].caption,
          chartArea: {
            height: "95%",
            top: "0%",
            width: "95%",
            left: "25%",
          },
            sliceVisibilityThreshold: .05,
            is3D: true,
        }}
        rootProps={{ "data-testid": "1" }}
      />
    </div>
  );
};
export default ChartPie;
