import React, { useRef, useEffect, useState } from "react";
import {LinkDD} from "../Stories/Links";
import {LinkSimple} from "../Stories/Links";
import {replaceLegCodes} from "../Story/Story";
import "../Story/Story.Style";
import "./TipSheet.css";
import axios from "axios";
import {skipPhenoms} from "./TipSheet_General";
import {ai_phenoms} from "./TipSheet_General";
import playIcon from "../../assets/images/play10up.svg";

let null_phenoms = [...skipPhenoms];
null_phenoms.push(...ai_phenoms);
null_phenoms.push("governor_veto_message");

const render_bullet = (tip,personas = null) => {
    let textContent = "";
    if ((tip.hasOwnProperty('data')) && tip.data.length > 1 && tip.data !== "nan") {
        textContent = replaceLegCodes(tip.data, personas);
    }

    if (tip.link_url?.length > 5){
        if (tip.link_text?.length > 1) {
            textContent = (
                <>
                    {textContent}&nbsp;({LinkSimple(tip.link_text, tip.link_url,null,false)})
                </>
            )
        }
        else {
            textContent = (
                <>
                    {textContent}&nbsp;({LinkSimple('link', tip.link_url,null,false)})
                </>
            )
        }
    }
    else
        textContent = tip.data;

    return textContent;
}

export default function TipSheetNotes({tipsheet, bill}) {
    const [notes, setNotes] = useState([]); // stores a list of notes
    const link_to_bill_page = LinkDD('transcript',
                            bill?.hearing_id,
                            'hea',null,false,null,
                            '?t='+ bill?.offset + '&f=' + bill?.file_id)

    let content = tipsheet.content;
    useEffect(() => {
        if (content && content.length > 0) {
            let firstNote = {
                title: "Story Idea",
                description: "The idea and summary block is produced by Generative AI with specific inputs and a custom prompt.",
                bullets: content?.filter(content_item => content_item.is_story_phenom &&
                    !null_phenoms.includes(content_item.phenom))?.
                    map(content_item => {
                        let all_bullets = [];
                        null_phenoms.push(content_item.phenom);
                        content_item.tips?.filter(tip => tip.type === "bullet").forEach((tip) => {
                            all_bullets.push(render_bullet(tip,tipsheet.personas));
                        });
                        return all_bullets;
                    }),
            };

            if (firstNote.bullets?.length > 0)
                firstNote.description += " The following are the inputs supplied:"

            if (tipsheet.bill_name?.toLowerCase() === "no bill discussed") {
                firstNote.description = <>The idea and summary block is produced by Generative AI based on the {
                LinkDD('transcript',
                            bill?.hearing_id,
                            'hea',null,false,null,
                            '?t='+ bill?.offset + '&f=' + bill?.file_id)
                } of the hearing.</>;
            }

            let secondNote = {
                title: "Bill Summary",
                description: "Bill summary is produced by Generative AI using facts about the bill and a custom prompt.",
                end_note: <>see {LinkDD("bill information", tipsheet.bill_id, 'bil',
                    tipsheet.bill_id, false)} from Digital Democracy.</>,
                bullets: ["Bill name: "+tipsheet.bill_name],
            }

            if (content?.filter(content_item => content_item.phenom === "bill_analysis_summary").length > 0) {
                let bas_phenom = content.filter(content_item => content_item.phenom === "bill_analysis_summary")[0];
                secondNote.bullets.push("The committee analysis produced by committee staff.");
            }

            secondNote.bullets.push("Bill digest (called \"bill summary\" on Digital Democracy) produced by the legislature.");

            let newNote = {
                title: "General Content",
                description: "General content for Digital Democracy Tip Sheets, including what is summarized and paraphrased "+
                    "with the help of Large Language Models, is derived through a series of AI modules designed to find "+
                    "basic facts or newsworthy observations about this discussion. ",
                bullets: [],
                end_note: <>see also Digital Democracy {LinkSimple("Data Sources and Methodology page",
                    "https://digitaldemocracy.calmatters.org/data-sources-methodology",
                    null,false)}</>
            }

            content.filter(content_item => !null_phenoms.includes(content_item.phenom)).forEach((content_item) => {
                if (content_item.tips?.length > 0) {
                    content_item.tips.forEach((tip) => {
                        newNote.bullets.push(render_bullet(tip,content_item.phenom));
                    })
                    null_phenoms.push(content_item.phenom);
                }
            })
            /*      ?.
                    map(content_item => {
                        let all_bullets = [];
                        if (tipsheet.billname && tipsheet.billname.length > 0) {
                            // ;
                            // all_bullets.push(LinkDD("Bill digest and latest analysis", tipsheet.bill_id,
                            //    'bil',null,false));
                        }
                        content_item.tips?.filter(tip => tip.type === "bullet").forEach((tip) => {
                            all_bullets.push(tip.data);
                        });
                        return all_bullets;
                    }),

            if (tipsheet.billname && tipsheet.billname.length > 0)
                secondNote.bullets.push("Bill name: "+tipsheet.billname);
            if (tipsheet. && tipsheet.billname.length > 0)
                secondNote.bullets.push("Bill name: "+tipsheet.billname);
            let newBullets= ["bill digest"];
            let bill_digest_phenom = content.filter(item => item.phenom === "bill_digest");
            if (bill_digest_phenom.length > 0) {
                newBullet = "bill digest";
                bill_digest_phenom[0].tips?.forEach((tip) => {
                    newBullet += ' '+tip.data;
                })

            }firstNote.bullets.push(newBullet);*/

            if (tipsheet.bill_name?.toLowerCase() === "no bill discussed")
                setNotes([firstNote, newNote]);
            else
                setNotes([firstNote, secondNote, newNote]);
        }
    },[content])
    //useEffect(() => {})

    return (
        <div className="bullet_tips_li">
            {notes.map((note, index) =>
                <ul>
                    <h3>{note.title}</h3>
                    <p>{note.description}</p>
                    {note.bullets.map((bullet, index) => <li>{bullet}</li>)}
                    <p>{note.end_note}</p>
                </ul>
            )}
        </div>
    );
}