import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

const fakeReturn = [
    ["Label", "Value"],
    ["Vanessa Leonard", 45.4545],
    ["Vanessa Leonard", 77.3],
    ["Sierra Club", 24.32],
    ["CalChamber", 90.9],
    ["CalCounties", 68.25],
  ];

export function getData(props) {
    return fakeReturn;
}

export const optionsExample = {
  width: 100,
  height: 100,
  redFrom: 0,
  redTo: 30,
  yellowFrom: 31,
  yellowTo: 0,
  minorTicks: 3,
    animation: {duration:20000, easing:'inAndOut'},
  format: 'percent',
};

export const controlsExample = [
        {
          controlEvents: [
            {
              eventName: "statechange",
              callback: ({ chartWrapper, controlWrapper }) => {
                console.log("State changed to", controlWrapper?.getState());
              },
            },
          ],
          controlType: "CategoryFilter",
          options: {
            filterColumnIndex: 0,
            //state: { 'selectedValues': [["CTA", 60]] },
            ui: {
              labelStacking: "horizontal",
              label: "",
              allowTyping: false,
              allowMultiple: false,
              allowNone: false,
            },
          },
        }];

export function ChartGauge(props) {
  //const [data, setData] = useState(getData(props));
  //const data = getData(props);

/*
  useEffect(() => {
    const id = setInterval(() => {
      setData(getData());
    }, 3000);

    return () => {
      clearInterval(id);
    };
  });
*/

  return (
    <Chart
      chartType="Gauge"
      width="100%"
      height="120px"
      data={props.data}
      options={props.options}
      formatters = {props.formatters}
    />
  );
}
export default ChartGauge;
