import styled from "styled-components";
import React from "react";

// need to fix mobile responsive
export const FilterWrap = styled.div`
    display: grid;
    grid-template-columns: 460px auto 175px;
    grid-template-areas: "a b c";

    justify-items: center;
    align-items: center; 
    /*background-image: linear-gradient(to right, #4c4b4c, #4c4b4c); #F1A340*/
    background-image: linear-gradient(to right, #0e4c60, #4395B7);
    height: 50px;
    position: relative;

    @media (max-width: 600px) {
        grid-template-columns: 100%;
        grid-template-rows: 100%;
    }
`;

export const LawmakerFilterWrap = styled.div`
    display: grid;
    grid-template-columns: 460px auto 50px;
    grid-template-areas: "a b c";

    justify-items: center;
    align-items: center; 
    background-image: linear-gradient(to right, #0e4c60, #4395B7);
    height: 50px;
    position: relative;
`;

export const FWrap= styled.div`
    display: flex;
    flex-direction: row;
    justify-items: left;
    align-items: center;
    padding-left: 0em;
    gap: 5px;
 
    @media (max-width: 600px) {
        flex-direction: column;
        padding-left: 180px;
    }
`;

// export const FilterButton = styled.button`
//     width: 120px;
//     border-radius: 5px;
//     padding-left: 10px;
//     margin: 0px;
//     border: 1px solid white;
//     outline: none;
//     align-items: center;
//     height: 30px;
//     color: white;
//     background-color: #4c4b4c;

//     display: grid;
//     grid-template-columns: 2fr 1fr;
//     justify-items: center;
//     align-items: center;
// `;

export const FilterButton = styled.button`
    width: 120px;
    border-radius: 5px;
    padding-left: 10px;
    margin: 0px;
    border: 1px solid white;
    outline: none;
    align-items: center;
    height: 30px;
    color: white;
    background-color: #0e4c60; /*#4c4b4c;*/

    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-items: center;
`;

export const FilterClearButton = styled.button`
    width: 60px;
`;

export const FilterTextBox = styled.input`
    background-color: #0e4c60;
    border: 1px solid white;
    height: 25px;
    border-radius: 5px;
    align-items: center;
    justify-items: center;
    outline: none;
    color: white;
    resize: horizontal;
    padding-left: 5px;
`;

export const FilterDropDown = styled.div`
    position: absolute;
    border: 1px solid white;
    background-color: #0e4c60;
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    border-radius: 5px;
    left: 50px;
    @media (max-width: 600px) {
        left: 40px;
    }
    z-index: 10;
`;

export const FilterBoxes = styled.div`
    display: grid;
    grid-template-rows: repeat(15, 20px);
    grid-template-columns: 150px 150px;
    grid-gap: 5px;
`;

export const ExclusionSection = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 20px);
    grid-gap: 5px;
`;

export const SearchButton = styled.button`
    background: #0e4c60;
    color: white;
    border: solid 1px white;
    border-radius: 8px;
    height: 23px;
    width: 80px;
    font-size: 14px;
    justify-self: center;
    &:hover {
        background-color: darkgray;
        transform: scale(1.2);
    }
`;

export const ClearButton = styled.button`
    background: #0e4c60;
    color: white;
    border: solid 1px white;
    border-radius: 8px;
    height: 23px;
    width: 80px;
    font-size: 14px;
    justify-self: center;
    &:hover {
        background-color: darkgray;
        transform: scale(1.2);
    }
`

export const SubmitButtonWrapper = styled.div`
    display: flex;
    justify-self: center;
    gap: 10px;
`


export const MenuItem = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 13px 20px 1fr;
    grid-gap: 4px;
`;
export const SmWrap = styled.div`
    position: relative;
`;
export const Checkbox = styled.input``;

export const Tags = styled.span`
    color: ${(props) => (props.active ? '#FCD70B':'#b7b7b8')};
`;

export const Img = styled.img`
    height: 20px;
    filter: ${(props) => (props.active ? 
    `brightness(0) saturate(100%) invert(84%) sepia(53%) saturate(2464%) hue-rotate(354deg) brightness(110%) contrast(98%)` :' ')
    };
`;

export const IconUp = styled.i`
    padding-top: 6px;
`;

export const IconDown = styled.i``;
// export const DownIcon = styled(Down);

export const SelectionButton = styled.button`
    width: 130px;
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
    border: 1px solid white;
    outline: none;
    align-items: center;
    height: 30px;
    color: white;
    background-color: #0e4c60;

    @media (max-width: 800px) {
        display: none;
    }
`;

export const StateTags = styled.div`
    display: grid;
    grid-template-rows: repeat(1, 30px);
    grid-template-columns: 150px 150px;
    grid-gap: 5px;
`;
export const StateTagsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SortButtons = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    
    height: 25px;
    justify-items: center;
    outline: none;
    padding: 8px;
    gap: 5px;
`

export const ToggleButton = styled.button`
    cursor: pointer;
    background-color: ${(props) => (props.active ? '#1187B2' : '#0e4c60')};
    transition: background-color 0.3s;
    
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0px;
    border-style: solid;
    border-width: ${(props) => (props.active ? '3px' : '1px')};
    border-color: ${(props) => (props.active ? '#FCD70B' : '#ffffff')};
    outline: none;
    align-items: center;
    height: 30px;
    color: white;
    // background-color: #0e4c60; /*#4c4b4c;*/

    &:hover {
        background-color: #3498db;
        color: #fff;
    }
`

export const AddButton = styled.button`
    cursor: pointer;
    background-color: #0e4c60;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    outline: none;
    align-items: center;
    height: 30px;
    width: 60px;
    color: white;

    &:hover {
        background-color: #3498db;
        color: #fff;
    }
`

export const LawmakerTags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
`

export const LawmakerTag = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
    border: 1px solid gray;
    border-radius: 6px;
    font-size: 12px;
    background-color: white;
    flex-shrink: 0;
`