import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import "./BarFormatTable.css";

export const exampleData = [
  ["Legislator", "Alignment", "Organization"],
  ["Adam", 90,"ACLU"],
    ["Adam", 40,"Coke"],
    ["Adam", 20,"Pepsi"],
  ["Eve", 72.3,"ACLU"],
  ["Steve", 55,"ACLU"],
  ["Cray", 43, "Pepsi"],
  ["Foaad", 56,"Coke"],
  ["Artemis", 78,"Coke"],
];

export const options = {
  allowHtml: true,
  showRowNumber: false,
  cssClassNames: { tableCell: "cell", width: "width" },
};

export const exampleFormatters = [
  {
    type: "BarFormat",
    column: 1,
    options: {
      width: 120,
      min: 0, 
      max: 100
    },
  },
];

// Example props.caption (opt to insert captions)
// index - index of category value
// attr - what attribute to retrieve the value if the data[index] is an object
// caption - text to put before value, if empty string (""), value will not show up
// styleFn(value) - custom styling function (takes in value)
// and returns inline style object
// componentFn(value) -- function to return custom component (takes in value)
export const exampleCaptions = [
  {
    index: 8,
    attr: "org_id",
    caption: "",
    componentFn: (val) => {
      return <p>example component {val}</p>
    }
  },
  {
    index: 9,
    caption: "Org Position",
    styleFn: (val) => {
      if (val === "For") {
        return { backgroundColor: "#386f1f", color: "white" };
      }
      return { backgroundColor: "#b0120a", color: "white" };
    },
  },
  { index: 14, caption: "Org Success Rate" },
  { index: 15, attr: "f", caption: "Org Total Contributions" },
];

export default function BarFormatTable(props) {
  const [selected, setSelected] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState(props.data);

  useEffect(() => {
    if (props.data?.length <= 1) return;

    let filterOpts = new Set();

    props.data.slice(1).forEach((row) => {
      let value = row[props.filterColumnIndex];
      if (props.filterColumnAttr && value !== null && typeof value === "object" && props.filterColumnAttr in value) {
        value = value[props.filterColumnAttr];
      }
      filterOpts.add(value);
    });

    let filterOptArr = [...filterOpts];
    filterOptArr.sort()
    setFilterOptions(filterOptArr);

    setSelected(filterOptArr[0]);
  }, [props.data])


  useEffect(() => {
    if (selected === "") {
      let data = props.data;
      setFilteredDatas(data);
    } else {
      let newData = props.data
        .slice(1)
        .filter((row) => {
          let value = row[props.filterColumnIndex];
          if (props.filterColumnAttr && value !== null && typeof value === "object" && props.filterColumnAttr in value) {
            value = value[props.filterColumnAttr];
          }
          return value === selected});
      newData.sort((a, b) => b[0]-a[0]);
      newData = [props.data[0], ...newData];
      setFilteredDatas(newData);
    }
  }, [selected])

  return (
    <>
      <select className="select" value={selected}
          onChange={(e) => setSelected(e.target.value)} >
          {filterOptions.length > 0 &&
            filterOptions.map((opt) => <option value={opt}>{opt}</option>)}
      </select>
      {filteredDatas.length >= 1 && props.caption && props.caption.map((c) => {
        if (c.index > filteredDatas[1].length) return;
        let value = filteredDatas[1][c.index];
        if (c.attr && value !== null && typeof(value) === "object" && c.attr in value) {
          value = value[c.attr]
        }        
        return <div className="caption" style={c.styleFn && c.styleFn(value)}>
          {c.caption && c.caption !== "" ? <>{c.caption} {value}</> : <></>}
          {c.componentFn && c.componentFn(value)}
         </div>;        
      })}   
      <br />       
      <Chart
        loader={<div>Loading Chart</div>}
        chartType="Table"
        width="100%"
        height="400px"
        data={filteredDatas}
        // data={props.data}
        options={options}
        formatters={props.formatters}
        chartWrapperParams={props.chartWrapperParams}
        controls={[]}
      />
    </>
  );
}
