export class DefaultDict {
    constructor(defaultFactory) {
      this.map = new Map();
      this.defaultFactory = defaultFactory;
    }
  
    get(key) {
      if (!this.map.has(key)) {
        this.map.set(key, this.defaultFactory());
      }
      return this.map.get(key);
    }
  
    set(key, value) {
      this.map.set(key, value);
    }
  
    has(key) {
      return this.map.has(key);
    }
  
    delete(key) {
      return this.map.delete(key);
    }
  
    keys() {
      return Array.from(this.map.keys());
    }
  
    values() {
      return Array.from(this.map.values());
    }
  
    entries() {
      return Array.from(this.map.entries());
    }
  
    size() {
      return this.map.size;
    }
  }
  
  