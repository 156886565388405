// DateRangePicker.js
import React, { useState } from "react";
import "./DateRange.css";

const DateRangePicker = ({
  onDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (endDate && newStartDate > endDate) {
      setEndDate(newStartDate);
    }
    onDateChange([newStartDate, endDate]);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    onDateChange([startDate, newEndDate]);
  };

  return (
    <div className="date-picker-container">
      <label>
        Start Date:
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          max={endDate || ""}
        />
      </label>
      <label>
        End Date:
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          min={startDate || ""}
        />
      </label>
    </div>
  );
};

export default DateRangePicker;
