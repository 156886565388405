import React, { useEffect, useRef, useState } from "react";
import BarIcons1 from "../../assets/images/sidebar_icons-13t.png";
import BarIcons2 from "../../assets/images/sidebar_icons-14t.png";
import BarIcons3 from "../../assets/images/sidebar_icons-15t.png";
import * as S from "./Sidebar.Styled";
import Modal from "../../UI/Modal";
import VideoTranscriptModal from "../Story/VideoTranscriptModal";
// import __html from "../../assets/AI4_JSON_Feb_4th.json";
// import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";

import { CopyToClipboard } from "react-copy-to-clipboard";
//import copy from "react-copy-to-clipboard";

export default function Sidebar(props) {
  const [modalArticleIsOpen, setModalArticleIsOpen] = useState(false);
  // const copy = __html.article_text;
  const copy = props.data;

  // const [modalTipIsOpen, setModalTipIsOpen] = useState(false);

  const [modalPureArticleIsOpen, setModalPureArticleIsOpen] = useState(false);

  var billAnalysis = copy.billanalysis_text;

  const formatBillAnalysis = (inText) => {
        return(inText.replace('\n','<br\>'));
    }

  var toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

    return [hours, minutes, seconds]
          .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };

  const formatTranscript = (transcript) => {
    if (!transcript) return;
    return (
      <table style={{ width: "100%" }} cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Time</th>
            <th style={{ width: "20%" }}>Speaker</th>
            <th style={{ width: "70%" }}>Content</th>
          </tr>
        </thead>
        <tbody>
          {copy.hearing_transcript.map((line) => {
            return (
              <tr
                style={{ marginBottom: "20px" }}
                data-starttime={line.start_time}
                data-endtime={line.end_time}
                tabindex="1"
              >
                <td>{toHHMMSS(line.start_time)}</td>
                <td>
                  {line.speaker_first} {line.speaker_last}
                </td>
                <td>
                  {line.utterance}
                  <VideoTranscriptModal
                    quoteData={{
                      vid_file_id: line.vid_file_id,
                      start_time: line.start_time,
                      transcript: copy.hearing_transcript,
                    }}
                  >
                    &nbsp;<a rel="noreferrer">&#x23E9;</a>
                  </VideoTranscriptModal>
                  <br />
                  <hr />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    if (modalArticleIsOpen) {
      markup.current.innerHTML = copy.billanalysis_text; //copy.article_html;
    }
  });

  const articleOpenClose = () => {
    setModalArticleIsOpen(!modalArticleIsOpen);
  };

  const markup = useRef(null);
  return (
    <S.SideBar>
      <S.SBIcons>
        {(copy.bill_name?.toLowerCase() !== "no bill discussed") &&
            <S.SideBarContainer>
              <S.SideBarIcon1
                src={BarIcons1}
                onClick={articleOpenClose}
                //mark={modalArticleIsOpen}
              />
              <div>bill analysis</div>
            </S.SideBarContainer>
        }

        {(copy.tipsheet_type !== "bill") &&
            <S.SideBarContainer>
              <S.SideBarIcon2
                src={BarIcons2}
                onClick={() => {
                  setModalPureArticleIsOpen(!modalPureArticleIsOpen);
                }}
                //mark={modalPureArticleIsOpen}
              />
              <div>transcript</div>
            </S.SideBarContainer>
        }

        {(copy.bill_name?.toLowerCase() !== "no bill discussed") &&
            <S.SideBarContainer>
                <S.SideBarIcon3
                    href={"https://digitaldemocracy.calmatters.org/bills/"+props.data.bill_id}
                    target="_blank"
                >
                <S.Image src={BarIcons3} />
            </S.SideBarIcon3>
                <div>link to bill</div>
            </S.SideBarContainer>
        }

        <Modal
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
            padding: "20px",
          }}
          showModal={modalArticleIsOpen}
          onClose={() => setModalArticleIsOpen(!modalArticleIsOpen)}
        >
          <S.Cancel
            className="fas fa-times"
            onClick={() => setModalArticleIsOpen(false)}
            style={{ cursor: "pointer" }}
          />
          <div ref={markup}></div>
        </Modal>
        <Modal
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
            padding: "20px",
          }}
          showModal={modalPureArticleIsOpen}
                    onClose={() =>
                        setModalPureArticleIsOpen(!modalPureArticleIsOpen)
                    }
        >
          {/*                    <S.ClipboardWrap>
                        <h3>Text</h3>
                        <CopyToClipboard text={copy.hearing_transcript}>
                            <S.ClipboardButton>
                                Copy to Clipboard
                            </S.ClipboardButton>
                        </CopyToClipboard>
                    </S.ClipboardWrap>*/}
          <S.Cancel
            className="fas fa-times"
            onClick={() => setModalPureArticleIsOpen(false)}
            style={{ cursor: "pointer" }}
          />
          {modalPureArticleIsOpen && <div>{formatTranscript(copy?.hearing_transcript)}</div>}
        </Modal>
      </S.SBIcons>
    </S.SideBar>
  );
}
