import React, { useEffect, useState } from "react";
import axios from "axios";
//import * as S from "./About.styled.js";
import background from "../../assets/images/background.jpg";
import ArchiveSearch from "../Filter/ArchiveSearch.js";
import { useHistory, useLocation } from 'react-router-dom';
import "./Archive.css";
import "../Stories/Stories.css"

const FEATURED_API = `${process.env.REACT_APP_FEATURED_API}archive/`;

export default function Archive(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("chrono");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allStories, setAllStories] = useState([]);
  const [filteredStories, setFilteredStories] = useState([]);
  const [storiesByMonth, setStoriesByMonth] = useState([]);

  // handle redirects upon expired token API requests
  let location = useLocation();
  const history = useHistory();
  const handleExpiredToken = () => {
    console.log("Expired session token, redirecting to login.")
    localStorage.removeItem('token');
    history.push({
      pathname: '/login',
      state: { from: location }
    });
  };

  const fetchAllStories = (API) => {
    let axiosT = axios.create({
      baseURL: `${process.env.REACT_APP_FEATURED_API}`,
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`
      }
    });
    axiosT
      .get("archive/")  
      .then((res) => {
        let data = res.data.tipsheets.map((story) => {
          let id = story.id.split("_");
          let newStory = { ...story };
          newStory["bill_title"] = story.title.split(" ")[0];
          
          // bill id, hid, did, state, date, bill title
          let searchAttributes = [
            id[1],
            id[2],
            id[3],
            story.state,
            story.date,
            story.title.split(" ")[0],
          ];
          newStory["search_attributes"] = searchAttributes;
          newStory["converted_date"] = new Date(story.date);
          return newStory;
        });
        setAllStories(data);
      })
      .catch((err) => {
        console.log(err);
        handleExpiredToken();
      });
  };

  useEffect(() => {
    fetchAllStories(FEATURED_API);
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setSearchTerm("");
  };

  useEffect(() => {
    let stories = allStories;
    let storiesByMonthDic = {};

    if (startDate !== "" || endDate !== "") {
      const start = new Date(startDate);
      const end = new Date(endDate);

      stories = stories.filter((story) => {
        const date = story.converted_date;
        if (startDate !== "" && endDate !== "") {
          return date >= start && date <= end;
        } else if (startDate !== "") {
          return date >= start;
        } else {
          return date <= end;
        }
      });
    }

    if (searchTerm !== "") {
      stories = stories.filter((story) =>
        story.search_attributes.some((item) =>
          item.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    stories.forEach((story) => {
        let segments = story.title.split("(")[1].split("-");
        let month = segments[0]+'-'+segments[1];
        if (!storiesByMonthDic.hasOwnProperty(month)) {
            storiesByMonthDic[month] = [];
        }
        storiesByMonthDic[month].push(story);
    });
    /*switch (sortBy) {
      case "title":
        stories.sort((a, b) => a.title.localeCompare(b.title));
        setFilteredStories([...stories]);
        break;
      case "chrono":
        stories.sort((a, b) => b.converted_date - a.converted_date);
        setFilteredStories([...stories]);
        break;
      default:
        setFilteredStories(stories);
        break;
    }*/
    setStoriesByMonth(storiesByMonthDic);
  }, [allStories, searchTerm, startDate, endDate, sortBy]);



  return (
    <div className="backgroundDiv" style={{backgroundImage: `url(${background})`}}>
        <ArchiveSearch
            searchTerm={searchTerm}
            handleInputChange={handleInputChange}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClear={handleClear}
            sortBy={sortBy}
            setSortBy={setSortBy}
        />
        <div className="archive-content">
          {storiesByMonth && Object.keys(storiesByMonth).toSorted((a,b) => a - b)
              .map((card_group, group_index) =>
                      <div className="story-periodic-group" key={group_index}>
                        <div className="period-header-archive" key={group_index}>
                          Month of {card_group}{' '}
                          ({storiesByMonth[card_group].length} TipSheet{(storiesByMonth[card_group].length > 1) ? 's' : ''})
                        </div>
                        <div className="archive-cards">
                        {storiesByMonth[card_group].map(record =>
                            <div className={"record-container"}>
                              <div className={"archive-record"}>
                            <a
                                key={record.id}
                                className="archive-links-list-item-link"
                                href={`/${record.id}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                              <span className={"archive-score"}>{record.score}</span>
                              <span className={"archive-record-metadata"}>{record.title}</span>
                              <span className={"archive-title"}>{record.tile_title}</span>
                            </a>
                          </div>
                            </div>
                        )}
                        </div>
                      </div>
              )
          }
        </div>
    </div>
  )
}
