import React, { useState, useRef, useEffect } from "react";
import "./expandable.css";

// put a unique key on component when using
// eg <Expandable key={"unique-key"}> <p> content </p> </Expandable>

function Expandable({ children, lines }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [needsExpand, setNeedsExpand] = useState(false);
  const [maxLines, setMaxLines] = useState(2);
  const contentRef = useRef(null);
  const resizeTimeoutRef = useRef(null);

  useEffect(() => {
    if (!Number.isFinite(lines)) return;
    setMaxLines(lines);
  }, [])


  useEffect(() => {
    const contentElement = contentRef.current;

    const handleResize = () => {
      clearTimeout(resizeTimeoutRef.current);
      resizeTimeoutRef.current = setTimeout(() => {
      const computedStyle = window.getComputedStyle(contentElement);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const maxHeight = lineHeight * maxLines;

      if (contentElement.scrollHeight > (maxHeight + 2)) { // 2px tolerance
        setNeedsExpand(true);
      } else {
        setNeedsExpand(false);
      }
    }, 100); 
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (contentElement) {
      handleResize(); // Initial check
      resizeObserver.observe(contentElement);
    }

    return () => {
      if (contentElement) {
        resizeObserver.unobserve(contentElement);
      }
      resizeObserver.disconnect();
      clearTimeout(resizeTimeoutRef.current);
    };
  }, [children]);

  return (
    <>
      <div
        className={`content ${isExpanded ? "expanded" : "collapsed"}`}
        style={{ lineHeight: "1.5em", maxHeight: isExpanded ? "none" : `${maxLines * 1.5}em` }} 
        ref={contentRef}
      >
        {children}
      </div>
      {needsExpand && (
        <span className="ellipsis" onClick={() => setIsExpanded(!isExpanded)}>
          see {isExpanded ? "less" : "more"}
        </span>
      )}
    </>
  );
}

export default Expandable;
