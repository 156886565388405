import React, { useState, useEffect} from "react";
import profile from '../../../../hooks/Profile';
import useForm from '../../../../hooks/useForm';
import SearchTags from "./SearchTags";
import LawMakerComponent from "./LawMakerComponent";
import swal from 'sweetalert';

export default function CoverageTopic(props) {
    const { S, allTags, userInput, handleCheckboxClick, user, setUserInput,changeSelected ,setUserContext} = props;

    const { values, handleChange } = useForm({
        initialValues: {
        }
    });


    const {  updateCoverageTopics, updateProfileWithoutPush } = profile();
    const [TopicTags, setTopicTags] = useState([]);
    const [Lawmakers, setLawmakers] = useState([]);

    const [getCoverageTopicsState, setCoverageTopicsState] = useState([]);
    const [getnew, setnew] = useState(false);
    const [locationCoveredIndexToRemove, setLocationCoveredIndexToRemove] = useState(-1);

    const [pause, setPause ] = useState(false);

    const ArrayToString = (array) => {
        return array.toString();
    }
    const StringToArray = (string) => {
        if (string) {
            return string.split(',');
        }
        return [];
    }
    useEffect(() => {
        if (!pause){
            setTopicTags(StringToArray(user.user_topic_tags));
            if (user.user_lawmakers)
            setLawmakers(JSON.parse(user.user_lawmakers));
            // let temp = await getCoverageTopics();
            let temp = user.user_location_covered;
            if (temp) {
                if (temp.length > 1) {
                    setCoverageTopicsState(temp);
                }
                else {
                    setnew(true);
                }
            }
            else {
                setnew(true);
            } 
            if (user) {
                let user_interests = StringToArray(user.user_interests.toLowerCase());
                setUserInput(user_interests);
            }
        }
    },[user])
    useEffect(() => {
        if (getnew) {
            let currentstate = getCoverageTopicsState;
            let newrow = {
                "id": null,
                "state_name": "",
                "county": "",
                "zip_code": ""
            }
            currentstate.push(newrow);
            setCoverageTopicsState(currentstate)
            setnew(false)
        }
    }, [getnew]);


    useEffect(() => {
        if (locationCoveredIndexToRemove != -1) {
            let currentstate = getCoverageTopicsState;
            currentstate.splice(locationCoveredIndexToRemove, 1);
            setCoverageTopicsState(currentstate)
            setLocationCoveredIndexToRemove(-1)
        }
    }, [locationCoveredIndexToRemove]);

    const getNewLocationCovered = () => {
        let i = 0;
        let list = [];
        while (true) {

            if (document.getElementsByName("state_name__" + i).length > 0) {
                let id = document.getElementsByName("location_covered_id" + i)[0].value;
                if (!id) {
                    id = null;
                }
                let newrow = {
                    "id": id,
                    "state_name": document.getElementsByName("state_name__" + i)[0].value,
                    "county": document.getElementsByName("county__" + i)[0].value,
                    "zip_code": document.getElementsByName("zip_code__" + i)[0].value
                }
                list.push(newrow);
            } else {
                break;
            }
            i++;

        }
        return list;
    }
   
    const getInterests = () => {
        return ArrayToString(Array.prototype.slice.call(document.getElementsByName('interest_checkbox[]')).filter(ch => ch.checked == true).map(e => e.value));
    }
    const updateAll = async () => {

        let user_interests = getInterests();
        let user_topic_tags = ArrayToString(TopicTags);
        let user_lawmakers = JSON.stringify(Lawmakers);

        let tempUSer = user;

        tempUSer.user_interests = user_interests;
        tempUSer.user_topic_tags = user_topic_tags;
        tempUSer.user_lawmakers = user_lawmakers;

        let user_location_covered = getNewLocationCovered();
        let result1 = await updateCoverageTopics(user_location_covered);
        let result = await updateProfileWithoutPush(tempUSer);
        if (result && result1){
            
            swal({
                title: "Update",
                text: "Profile updated successfully!",
                icon: "success",
              }).then((value) => {
                // window.location.reload(false);
                // changeSelected("Account_Information");
                setUserContext("no");
                changeSelected("Account_Information");
              });


            // user.user_topic_tags = user_topic_tags;
            // user.user_interests = user_interests;
            // setPause(false);
            // user.user_lawmakers = user_lawmakers;
        }
        else {
            swal("Profile Update Failed");
        }
    }

    const changevalue = (e) => {
        let nameIndex = e.target.name.split("__");
        let currentstate = getCoverageTopicsState;
        currentstate[nameIndex[1]][nameIndex[0]] = e.target.value;
        setCoverageTopicsState(currentstate)
        handleChange(e);
    }
    const removeCoverage = (index) => {
        setLocationCoveredIndexToRemove(index);
    }
   
    return (
        <S.Body>
            {/* LOCATION COVERED (disabled by Andrew Chan) */}
            {/* <S.BodyBox id="location_covered" className="BodyBox">
                <S.BodyBoxTitle className="BodyBoxTitle">LOCATION COVERED</S.BodyBoxTitle>
                <div className="profile-form coverage-form">
                    <div className="profile-form-group">
                        <label className="profile-label">State</label>

                    </div>
                    <div className="profile-form-group">
                        <label className="profile-label" >County</label>

                    </div>
                    <div className="profile-form-group">
                        <label className="profile-label">Zip Code</label>

                    </div>
                    <div className="profile-form-group">
                    </div>
                </div>
                {getCoverageTopicsState && getCoverageTopicsState.map(({ id, state_name, county, zip_code }, i) => {
                    return <div className="profile-form coverage-form" id={"profile-form coverage-form-" + i}>
                        <div className="profile-form-group">
                            <input value={id} type="hidden" name={"location_covered_id" + i} />
                            <input
                                value={state_name}
                                class="profile-form-control"
                                name={"state_name__" + i}
                                type="text" onChange={function (e) { changevalue(e); }} />
                        </div>
                        <div className="profile-form-group">
                            <input
                                value={county}
                                class="profile-form-control"
                                name={"county__" + i}
                                type="text" onChange={function (e) { changevalue(e); }} />
                        </div>
                        <div className="profile-form-group">
                            <input
                                value={zip_code}
                                class="profile-form-control"
                                name={"zip_code__" + i}
                                type="text" onChange={function (e) { changevalue(e); }} />
                        </div>
                        <div className="profile-form-group">
                            <button className="removeBtn" onClick={function () { removeCoverage(i); }}>Remove</button>
                        </div>
                    </div>
                })}

                <div className="button-location-covered" onClick={function () { setnew(true) }}>
                    Add Another Location
                </div>
            </S.BodyBox> */}
            {/* INTERESTS */}
            <S.BodyBox className="BodyBox">
                <S.BodyBoxTitle className="BodyBoxTitle">INTERESTS</S.BodyBoxTitle>
                <S.FilterBoxes className="FilterBoxes">
                    {allTags.map(({ svg, icon }, i) => {
                        let ta_display = icon === "public_safety"
                            ? "Public Safety"
                            : icon.substring(0,3) === "geo"
                            ? icon.substring(3).replace(/_/g,' ').toUpperCase()
                            : icon[0].toUpperCase() + icon.substring(1);
                        return (
                            <label className="check-container" key={i}>
                                {ta_display}
                                <input
                                    value={ta_display}
                                    className="interest_checkbox_class"
                                    name="interest_checkbox[]"
                                    type="checkbox"
                                    checked={userInput.includes(icon) === true}
                                    onChange={() => {
                                        handleCheckboxClick(icon);
                                    }}
                                />
                                <span className="checkmark">

                                </span>
                            </label>
                        );
                    })}
                </S.FilterBoxes>
            </S.BodyBox>
            {/* TOPIC TAGS */}
                {/* temporarily removed by andrew chan */}
                {/* <SearchTags  
                    TopicTags = {TopicTags}  
                    setTopicTags={setTopicTags}  
                /> 
                */}
                <LawMakerComponent 
                    Lawmakers={Lawmakers}  
                    setLawmakers={setLawmakers}
                />
                <S.BodyBox className="BodyBox">
                   <div style={{ "text-align": "center" }}>
                        <button type="button" class="button-account-information" onClick={updateAll}>Save All</button>
                    </div>
                </S.BodyBox>
        </S.Body>
    );
}