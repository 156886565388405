// Handles creating HTML links to the Digital Democracy site
// To be used on both tipsheet content and cards
import { Link } from "react-router-dom";

const base_url = "https://digitaldemocracy.calmatters.org";
const default_target = "_blank";
const entity_types = {'leg':'legislators','org':'organizations',
    'hea':'hearings','per':'people','bil':'bills','com':'committees','alt':'','other':''};

function __LinkDD(inText, inUrl, inKey = null, returnText=true) {
    if ((inText == null) || inUrl == null) {
        return null;
    }
    let newKey = inKey;
    if (inKey == null) {
        newKey = Date.now();
    }
    if (returnText)
        return `<a href= ${inUrl} target={${default_target}} key=${newKey}>${inText}</a>`;

    return (<Link to={{pathname: inUrl}} target={default_target} key={newKey}>{inText}</Link>)
}

export function LinkSimple(inText,inUrl,inKey = null, returnText = true){
    return __LinkDD(inText,inUrl,inKey,returnText);
}

export function LinkDD(inText, id, inType, inKey = null, returnText = true, altText=null,suffix='') {
    let url = base_url;
    if (id && !isNaN(+id))
        id = id.toString();

    if (inType != null) {
        switch(inType) {
            case 'leg':
                url+= '/'+entity_types[inType]+'/'+(inText.toLowerCase()).split(' ').join('-')+'-'+id;
                break;
            case 'hea':
                url+='/'+entity_types[inType]+'/'+id+suffix;
                break;
            case 'other':
                break;
            default:
                url+= '/'+entity_types[inType]+'/'+id;
                break;
        }
        //console.log('LinkDD: ',inText,url,inKey,__LinkDD(inText,url,inKey));
        if(altText)
            return __LinkDD(altText,url,inKey,returnText);
        return __LinkDD(inText,url,inKey,returnText);
    }
}

/* link formatters: these are functions to be specifically used for
    Google React Chart objects. A "formatter" is an array of objects, like this one
    with only one column that it is formatting:

    const M_formatters = [
      {
        type: "PatternFormat",
        column: [0, 1],
        options: '<a href='+linkExample+'>{0}</a>',
      },
    ];

    we pass them to the Google Chart like this:

    <ChartTable formatters={M_formatters} dataTable={Personas} />

    see more here: https://www.react-google-charts.com/examples/table
 */
const M_formatters = [
      {
        type: "PatternFormat",
        column: [0, 1],
        options: '<a href="{1}">{0}</a>',
      },
    ];
const LinkFormatSet = []
export function LinkFormatColumn(colNum,colLink, text=null) {
    let replacementText = colNum.toString();
    if (text) {
        replacementText = text;
    }
    let ret_val = [{
        type: "PatternFormat",
        column: [colNum,colNum+1],
        //options: '<a href="{1}">{0}</a>',
        options: '<a href=' +'"'+ base_url+'/'+colLink + '"'+'>{'+replacementText+'}</a>',
    },
    ];
    console.log("LinkFormat: ",ret_val,M_formatters);
    return ret_val;
}


