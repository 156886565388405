import styled from "styled-components";

export const PageTitle = styled.h1`
    font-size: 30px;
    padding-left: 60px;
`;
export const StaffWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    background-color: #e5e5e5;
    padding-bottom: 3.5rem;
`;
export const StaffCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-width: 2px;
    border-color: rgb(109, 232, 236);
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background: #ffffff;
    min-height: 345px;
`;
export const Textrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0px;
`;
export const Name = styled.div`
    font-size: 28px;
    font-weight: 700;
`;
export const Title = styled.div`
    font-size: 20px;
`;
export const University = styled.div`
    font-size: 20px;
`;
export const ImageDescWrap = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 20px;
`;
export const Photo = styled.img`
    width: 160px;
    height: 200px;
    padding: 20px;
`;
export const Description = styled.div`
    padding-right: 20px;
    padding-top: 20px;
`;
