import styled from "styled-components";

export const HorizonTagsBarWrap = styled.div`
    display: block;
    justify-items: left;
    align-items: left;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 10px;
    grid-auto-rows: 50px;
    height: 50px;
    width: 100%;
    padding-bottom:10px;
    font-size: 35px;
    overflow: hidden;

    @media (max-width: 600px) {
        display: none;
    }
`;

export const Img = styled.img`
    height: 16px;
`;

export const Para = styled.p`
    margin: 5px;
`;

export const Tag = styled.div`
    display: inline-grid;
    grid-column-gap: 6px;
    grid-auto-flow: column;
    align-items: center;
    font-size: 12px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 6px;
    margin: 5px;
`;
