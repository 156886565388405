import React, { useRef, useEffect, useState } from "react";
// import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";
import "../Story/Story.Style";
import "./TipSheet.css";
import "../Chart/StackedBar";
import "../Story/Story.css";

import {getDuration, Tooltip} from "../Stories/Stories.js";
import { LinkDD } from "../Stories/Links";
import Expandable from "../Expandable/Expandable.js";

export default function TipSheetSummary(props) {
  const [summary, setSumm] = useState([]);

  useEffect(() => {
        setSumm(props);
  }, [props]);

  //console.log("TipSheetSummary props: ",summary);
  let outCome = "";     //contain the entire <li> line for the summary bullet points
  let billSubject = ""; //contains the entire <li> line for bill subject
  let billTitle = "";
  let billAnalysis = "";
  let billNameLink = "";
  let billAuthors = "";

  /*if (summary.bill_subject?.length > 3) {
    billSubject = (
      <li>
        <Expandable key={"bill-subjects"}>
            <b>Bill Subjects:</b> {summary.bill_subject}
        </Expandable>        
      </li>
    );
  }*/

  /*    Removed Bill Title, as it was too crowded in the summary
        leaving it here in case we want it back later

  if (summary.bill_title?.length > 3) {
    billTitle = (
      <li>
        <Expandable key={"bill-title"}>
            <b>Bill Title:</b> {summary.bill_title}
        </Expandable>
      </li>
    );
  }
  */
  //construct the vote string
  // No Bill
  //
  if (summary.vote_outcome && summary.vote_outcome.length > 3) {
      let vote_result = "";
      let voteReportSpanClass = "unknown";
      let voteString = "";
      let official = "";

      if (summary.vote_outcome.toLowerCase().slice(0,3) === "pas") {
          vote_result = "pass";
          official = " (official)";
      } else if (summary.vote_outcome.toLowerCase().slice(0,3) === "fai") {
          vote_result = "fail";
          official = " (official)";
      } else if (parseInt(summary.voteData[1][4]) >
          parseInt(summary.voteData[2][4]) + parseInt(summary.voteData[3][4])) {
          vote_result = "pass";
      } else {
          vote_result = "fail";
      }
      voteReportSpanClass = "vote-"+vote_result;

      if (summary.voteData && summary.voteData.length > 1) {
        voteString = summary.voteData[1][4] + '-';
        voteString += summary.voteData[2][4] + '-';
        voteString += summary.voteData[3][4];
      }

      outCome = (<li><b>Vote Results: </b>{voteString} <span className="vote-report">
          <span className={voteReportSpanClass}>{vote_result}{official}</span></span></li>);

  }

  if (summary.bill_ai_summary?.length > 0) {
    billAnalysis = (
      <li>
        <Expandable key={"bill-summary"} lines={8}> 
            <b>Bill Summary: </b>
            {summary.bill_ai_summary[0].text}
            {
                <div className="information-tip">
                    The bill summary above is created by "generative AI" which is used to simplify language in the bill analysis. You can see the original language in the "Notes on Methodology" section below. The bill analysis is written by staff in the Legislature and is linked in the left column of this TipSheet.
                </div>
            }
        </Expandable>
      </li>
    );
  }
  else if (summary.bill_analysis_summary?.length > 0) {
    billAnalysis = (
      <li>
        <Expandable key={"bill-summary"} lines={8}>
            <b>Bill Summary:</b>
        {summary.bill_analysis_summary[0].text}
        </Expandable>        
      </li>
    );
  }

  if (summary.authors) {
      billAuthors = <li><b>Authors:</b>
                <Expandable key="authors-list">
                    <ul>{summary.authors?.sort((a,b) =>
                            {
                                const contributionOrder = {
                                    'lead author': 1,
                                    'principal coauthor': 2,
                                    'coauthor': 3
                                  };

                                if (a.contribution.toLowerCase() !== b.contribution.toLowerCase()) {
                                    let aOrder = 4;
                                    let bOrder = 4;
                                    if (a.contribution.toLowerCase() in contributionOrder) {
                                        aOrder = contributionOrder[a.contribution.toLowerCase()]
                                    }
                                    if (b.contribution.toLowerCase() in contributionOrder) {
                                        bOrder = contributionOrder[b.contribution.toLowerCase()]
                                    }
                                    return aOrder - bOrder;
                                } else {
                                    return a.last.localeCompare(b.last)
                                }
                            })
                        .map((author) => (
                            <li>
                                {author.contribution}&nbsp;
                                {(author.title?.slice(0,1) === "A")? "Assemblymember":"Senator"}
                                &nbsp;{LinkDD(author.first.trim()+' '+author.last.trim(),Number(author.pid),'leg',author.pid,false)}
                                ({author.party?.slice(0,1)})
                            </li>
                        ))
                    }</ul>
                </Expandable></li>;
  }

  if (summary.bill && summary.bill.length > 2) {
    if(summary.bill.toLowerCase() === "no bill discussed") {
        billNameLink = <b>Informational discussion</b>;
        billAnalysis = "";
        billSubject = "";
        billAuthors = "";
    }
    else{
        let duration = getDuration(summary.video_duration);
        let duration_text = "";
        if (duration.length > 1){
            duration_text = <span>({duration})</span>;
        }
        billNameLink =
        <li>
            <b>Bill:</b> {LinkDD(summary.bill,summary.bill_tag,'bil',summary.bill_tag,false)}
            {' '}{duration}
        </li>
    }
  }
  let committee = "";
  if (summary.committee?.length > 5) {
      committee =
          <li>
            Meeting of {LinkDD(summary.committee, Number(summary.committee_id),
              'com',summary.committee_id, false)}
          </li>
  }
  //console.log("summary bill",summary.bill);
  return (
        <div className="summary_section">
            <ul>
                {/*(summary.publish_date_str) && (<li>TipSheet generated on {summary.publish_date_str}</li>)*/}
                {billNameLink}
                {committee}
                <li>Date: {summary.date_text}</li>
                {billSubject}
                {billAnalysis}
                {outCome}
                {billAuthors}
            </ul>
        </div>
  );
}
