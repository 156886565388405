import React from "react";
import * as S from "./HorizonTagsBar.Styled";

export default function HorizonTagsBar({ dataFromParent, dataFromParent2, handleCheckboxClick }) {
    return (
        <S.HorizonTagsBarWrap>
            {dataFromParent.map(({ svg, icon }, index) => {
                return index < 9 ? (
                    <S.Tag
                        selected22={dataFromParent2.includes(icon)}
                        name={icon}
                        key={index}
                    >
                        <span className="tag-remove" onClick={function () { handleCheckboxClick(icon) }}></span>
                        <S.Img name={icon} src={svg.default} />
                        <span className="tag-content" name={icon}>
                            {icon === "public_safety"
                                ? "Public Safety"
                                : icon.substring(0,3) === "geo" ? icon.substring(3)
                                        .replace(/_/g,' ')
                                        .toUpperCase()
                                : icon[0].toUpperCase() + icon.substring(1)}
                        </span>
                    </S.Tag>
                ) : null;
            })}
        </S.HorizonTagsBarWrap>
    );
}
