import React from "react";
import { Chart } from "react-google-charts";

export const data_example = [
  ["Vote", "Democratic", "Republican","Other",{ role: "annotation" }],
  ["Aye", 5,2,0,'7'],
  ["No", 3,3,0,'6'],
  ["NVR", 2,2,1,'5'],
];

export const options = {
  title: "Vote Breakdown by Party",
  chartArea: { width: "50%" },
  isStacked: true,
  hAxis: {
    title: "count",
    minValue: 0,
    ticks: [],
    //ticks: [0, 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
  },
  vAxis: {
    title: "vote",
  },
  annotations: {
    textStyle: {
      //fontName: 'Times-Roman',
      //fontSize: 18,
      //bold: true,
      italic: true,
      // The color of the text.
      color: 'black',
      // The color of the text outline.
      //auraColor: '#d799ae',
      // The transparency of the text.
      //opacity: 0.8
    }
  }
};


export default function StackedBar(props) {
  return (
    <Chart
      chartType="BarChart"

      height="90px"
      data={props.data}
      options={options}
    />
  );
}