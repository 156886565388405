// need to figure out a more elegant way for this.
// dynamic, make it read from tsv if no svg use a "default"
// also get states from the tsv spreadsheet

// TODO: ...

const tags = [
    "aging",
    "agriculture",
    "arts",
    "budget",
    "business",
    "communication",
    "economy",
    "education",
    "elections",
    "employment",
    "entertainment",
    "environment",
    "finance",
    "healthcare",
    "housing",
    "insurance",
    "judiciary",
    "law",
    "medication",
    "public_safety",
    "rules",
    "sports",
    "taxations",
    "transportation",
    "utilities",
    "water",
    "wildlife",
    "women",
    "geo_los_angeles",
    "geo_fresno",
    "geo_bay_area",
];

export default tags;

// const tagsPath = [
//     aging,
//     agriculture,
//     arts,
//     budget,
//     business,
//     communication,
//     economy,
//     education,
//     elections,
//     employment,
//     entertainment,
//     environment,
//     finance,
//     healthcare,
//     housing,
//     insurance,
//     judiciary,
//     law,
//     public_safety,
//     rules,
//     sports,
//     taxations,
//     transportation,
//     utilities,
//     water,
//     wildlife,
// ];

// import aging from "../../assets/light_gray_icons/aging.svg";
// import agriculture from "../../assets/light_gray_icons/agriculture.svg";
// import arts from "../../assets/light_gray_icons/arts.svg";
// import budget from "../../assets/light_gray_icons/budget.svg";
// import business from "../../assets/light_gray_icons/business.svg";
// import communication from "../../assets/light_gray_icons/communication.svg";
// import economy from "../../assets/light_gray_icons/economy.svg";
// import education from "../../assets/light_gray_icons/education.svg";
// import elections from "../../assets/light_gray_icons/elections.svg";
// import employment from "../../assets/light_gray_icons/employment.svg";
// import entertainment from "../../assets/light_gray_icons/entertainment.svg";
// import environment from "../../assets/light_gray_icons/environment.svg";
// import finance from "../../assets/light_gray_icons/finance.svg";
// import healthcare from "../../assets/light_gray_icons/healthcare.svg";
// import housing from "../../assets/light_gray_icons/housing.svg";
// import insurance from "../../assets/light_gray_icons/insurance.svg";
// import judiciary from "../../assets/light_gray_icons/judiciary.svg";
// import law from "../../assets/light_gray_icons/law.svg";
// import public_safety from "../../assets/light_gray_icons/public_safety.svg";
// import rules from "../../assets/light_gray_icons/rules.svg";
// import sports from "../../assets/light_gray_icons/sports.svg";
// import taxations from "../../assets/light_gray_icons/taxations.svg";
// import transportation from "../../assets/light_gray_icons/transportation.svg";
// import utilities from "../../assets/light_gray_icons/utilities.svg";
// import water from "../../assets/light_gray_icons/water.svg";
// import wildlife from "../../assets/light_gray_icons/wildlife.svg";
