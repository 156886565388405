import React, { useRef, useEffect, useState } from "react";
import VideoTranscriptModal from "../Story/VideoTranscriptModal";
// import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";
import "../Story/Story.Style";
import "./TipSheet.css";
import { LinkDD } from "../Stories/Links";
import CustomCarousel from "../CustomCarousel/CustomCarousel";

function getVote(pid, vote_detail) {
  //console.log('getVote: ',pid,vote_detail);
  if(vote_detail?.data && pid && pid > 0) {
    let votes = vote_detail.data.filter(item => item.pid === pid);
    votes = votes.map(filteredItem => filteredItem.vote);
    if (votes?.length > 0)
      return votes[0];
  }
  return null;
}

function getPosition(pid,hearing_transcript,bill_authors = null){
  const authors = bill_authors.filter(item => pid === item.pid).map(author => author.contribution);
  if (authors?.length > 0) {
    return (authors[0]);
  }
  const utterances = hearing_transcript.filter(item => pid === item.speaker_pid)
      .map(utterance => utterance.alignment?.toLowerCase());
  if (utterances?.length > 0) {
    const position = utterances[0];
    //console.log('getPosition: ', position);
    return(position);
  }
  return null;
}

function position_string(vote, position) {
  //console.log('position_string: ',vote,position);
  if (vote !== null) {
    if (vote?.toLowerCase() === 'aye') {
      return (<span className="vote-pass">voted 'Aye' on bill</span>);
    }
    if (vote?.toLowerCase() === 'no') {
      return (<span className="vote-fail">voted 'No' on bill</span>);
    }
  }
  if (position !== null) {
    if (position?.toLowerCase() === 'for') {
      return (<span className="vote-pass">position stated: FOR</span>);
    }
    if (position?.toLowerCase() === 'against') {
      return (<span className="vote-fail">position stated: AGAINST</span>);
    }
    if (position?.toLowerCase().includes('author')) {
      return (<span className="vote-pass">{position.toUpperCase()}</span>);
    }
    if (position.length > 3){
      return (<span className="vote">position stated: {position.toUpperCase()}</span>);
    }
  }
  return null;
}

export default function TipSheetQuotes(props) {
  const [quotes, setQuotes] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const hearing_id = props.jsonid?.split('_')[2];
  const votes = (props.vote_detail && props.vote_detail.length > 0) ? props.vote_detail[0] : null;

  useEffect(() => {
    if (Array.isArray(props.pullquotes)) {
      const quotesData = props.pullquotes.map((quote) => ({
        text: quote.quote_text,
        aff: quote.quote_author_affiliation,
        url: `https://videostorage-us-west.s3-us-west-2.amazonaws.com/videos/${quote.vid_file_id}/${quote.vid_file_id}.mp4#t=${quote.start_time}`,
        directUrl: LinkDD("⏩",hearing_id, 'hea',null,false,null,
            '?t='+quote.start_time+'&f='+quote.vid_file_id),
        vote: getVote(quote.quote_author,votes),
        position: getPosition(quote.quote_author,props.hearing_transcript,props.bill_authors),
      }));
      setQuotes(quotesData);
      quotesData.forEach((quote) => {
        if (props.onVideoExtracted) {
          props.onVideoExtracted(quote.url);
        }
      });
    }
  }, [props.pullquotes]);

  function copyToClipboard(text, aff) {
    const textToCopy = `"${text}"  --${aff}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500);
    });
  }

  let quoteComponents = quotes.map((quote, index) => (
    <div>
      <div key={index} className="pullquote">
        {quote.text}
        {" "}
        <span
          className="copy-icon"
          onClick={() => copyToClipboard(quote.text, quote.aff)}
        >
          &#128203;
        </span>
        {isCopied && <span className="quote-notif">Quote copied!</span>}
      </div>
      <div key={index + 1} className="quote_author_affiliation">
        {quote.aff}{' '}
        {!props.informational_hearing &&
            <>[
              {position_string(quote.vote, quote.position)}
              ]
            </>}{' '}
        {quote.directUrl}
        {/*<VideoTranscriptModal  {quote.directUrl}
          key={index + 2}
          quoteData={{
            ...props.pullquotes[index],
            transcript: props.transcript,
          }}
        >
          &nbsp;<a rel="noreferrer">&#x23E9;</a>
        </VideoTranscriptModal>*/}
      </div>
    </div>
  ));

  return (
    <div className="tips_quotes">
      {quoteComponents && quoteComponents?.length > 1 ? <CustomCarousel components={quoteComponents}/> : quoteComponents[0]}      
    </div>
  );
}
