import logo from "../../../assets/images/calmatters.jpg";
import background from "../../../assets/images/background.jpg";

import * as S from "../Account.Style";
import "../Account.css";
import useForm from '../../../hooks/useForm';
import useAuth from '../../../hooks/useAuth';
import { useHistory} from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

export default function Login(props) {
    let history = useHistory();
    const { values, handleChange } = useForm({
        initialValues: {
            email: '',
            password: ''
        }
    });

    const { loginUser, error } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (validate()) {
            await loginUser(values);
        }
       
    }
    const validate = () => {
        let isValid = true;
        let input = values;
        document.getElementById('form-error').innerHTML = "";
        document.getElementById('message-email').innerHTML = "";
        document.getElementById('message-password').innerHTML = "";
        
        if (!input["email"]) {
            isValid = false;
            document.getElementById('message-email').innerHTML = "Please enter your email Address.";
        }
        if (input["email"]) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                document.getElementById('message-email').innerHTML = "Please enter valid email address.";
            }
        }
        if (!input["password"]) {
            isValid = false;
            document.getElementById('message-password').innerHTML = "Please enter your password.";
          }
        return isValid;
    }
    return (
        <div className="backgroundDiv" style={{ backgroundImage: `url(${background})` }}>
            <div className="account-brand">
                <S.Brand  onClick={function(){history.push('/')}}>
                    <S.MenuLink to="#">
                        <S.Logo src={logo} alt="AI TipSheets" />
                    </S.MenuLink>

                    <S.MenuLink to="#">
                        <S.Name>AI TipSheets by CalMatters</S.Name>
                    </S.MenuLink>
                </S.Brand>
            </div>
            <div style={{ display: "flex" }}>
                <div className="account-container">
                    <div className="account-message" id="form-error">
                        </div>
                    <h3>Sign In with Google</h3>
                    <div className="google-button">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                loginUser(credentialResponse.credential, credentialResponse.clientId)
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    </div>
                    {/*
                    <div className="account-bottom">Don`t have an account?
                        <a className="account-anchor" href="#" onClick={function(){history.push('/signup')}}>SIGN UP</a>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
}
