import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { JsonView, collapseAllNested, allExpanded } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export default function StoriesRaw(props) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    let location = useLocation();
    const history = useHistory();

    const handleExpiredToken = () => {
        console.log("Expired session token, redirecting to login.")
        localStorage.removeItem('token');
        history.push({
            pathname: '/login',
            state: { from: location }
        });
    };

    const handleOpenJson = async () => {
        let API_URL = `${process.env.REACT_APP_FEATURED_API}`;
        let axiosT = axios.create({
            baseURL: `${API_URL}`,
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        });
        axiosT
            .get("/")
            .then((response) => {
                if (response.status !== 200) {
                    const errorDetails = {
                        status: response.status,
                    };
                    console.log("error error: ", response.status);
                    throw errorDetails;
                }
                const formattedJson = JSON.stringify(response.data.files);
                const blob = new Blob([formattedJson], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            })
            .catch((err) => {
                if (err.status === 401) {
                    handleExpiredToken();
                }
                setError(err.status);
            })
    };

    if (error) return <div>API error, status {error}</div>;

    return (
        <div>
            <button onClick={handleOpenJson}>View JSON Data</button>
        </div>
    );
}