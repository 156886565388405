import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { UserContext } from './../hooks/UserContext';


export default function PrivateRoute(props) {   
    const { user, isLoading } = useContext(UserContext); 
    let location = useLocation();

    const { component: Component,
        ...rest } = props;

      if(isLoading) {
          return "Loading..."
        }

      if(user){
        return ( <Route {...rest} render={(props) => (<Component {...props}/>)}/>)
      } else {
        return <Redirect to={{
          pathname:'/login',
          state: { from: location } // save original url to redirect back to after login
        }} /> 
      }

}




