import React, { useRef, useEffect, useState } from "react";
// import __html from "../../assets/CA_201720180SB649_48_54100_31961v7.json";
import "../Story/Story.Style";
import "./TipSheet.css";
import "../Chart/StackedBar"
import "../Story/Story.css"
import ChartGauge from "../Chart/ChartGauge";

import { getDuration } from "../Stories/Stories.js";
import { LinkDD } from "../Stories/Links";
import Expandable from "../Expandable/Expandable.js";

// data{'title': , 'value': 'floorCheck': 'floorMessage': 'noun_prefixToActivity': 'valueUnits': }

const format_num = (num,format) => {
    if (format === "donations") {
        return (num*1000).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0});
    }
    else return num.toLocaleString();
};

function InfoGauge(data,key) {
    return(
       <div className="information-gauge-panel" key={key}>
           <ChartGauge data = {[['trait','number'],[data['title'],
               {v:data['value'],f:data['value_prefix']+data['value'].toLocaleString()+data['value_suffix']}]]}
                options = {{
                        width: 120,
                        height: 120,
                        redFrom: 0,
                        redTo: 0,
                        yellowFrom: Number(data['q1']),
                        yellowTo: Number(data['q3']),
                        minorTicks: data.minorTicks ? data['minorTicks'] :5,
                        max:data['max_display'],
                        animation: {duration:2000, easing:'inAndOut'},
                }}
           />
           <div className="information-gauge-text">
               <Expandable key={key+'-text'} lines={3}>
                {data['floorCheck'] === true ? data['floorMessage'] :
                    <p>{data['noun_prefixToActivity'].charAt(0).toUpperCase()+data['noun_prefixToActivity'].slice(1)} activity is <b>
                        {data['value'] < data['q1'] ? 'low' :
                            data['value'] > data['max_display'] ? 'very high':
                                data['value'] > data['q3'] ? 'high' :
                                    'typical'}</b>:&nbsp;
                        {format_num(data['value'],data['noun_prefixToActivity'])} {data['valueUnits']}.&nbsp;
                        Typical range is {format_num(data['q1'],data['noun_prefixToActivity'])} to&nbsp;
                        {format_num(data['q3'],data['noun_prefixToActivity'])}.</p>
                    /*with a statistical mean of {data['mean']} and standard deviation of {data['std_dev']}. The median
                        value is {data['median']}. (n = 5774 latest bill discussions from the 2023-2024 legislative session
                        as captured by the Digital Democracy Database)</p>*/
                }
               </Expandable>
           </div>
       </div>
    )
}

export default function TipSheetInfoGauges(props) {
    return (props.data?.length > 0 &&
        <>
            <div className="information-gauge-title">Activity in this bill discussion</div>
            <div className="information-gauges">
            {
                props.data.map((gaugeData,index) => InfoGauge(gaugeData,index))
            }
            </div>
        </>
    )
}