import styled from "styled-components";
import { Link } from "react-router-dom";

export const Brand = styled.div`
    display: flex;
    width: 100%;
    color: #4c4b4c;
    grid-template-columns: 60px 1fr;
    align-items: center;
    justify-content: center;
    grid-area: brand;
`;
export const Logo = styled.img`
    display: grid;
    max-width: 50px;
    justify-self: center;
    margin-left: 20px;
`;
export const MenuLink = styled(Link)`
    text-decoration: none;
    color: #4c4b4c;
`;
export const Name = styled.h2`
    font-family: roboto; //pt-serif;
    font-weight: 700;
    font-size: 32px;
    text-align: left;
    color: #4c4b4c;
    padding-left: 20px;
`;