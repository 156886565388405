import { useState, useEffect } from 'react';
import axios from './axios';

export default function useFindUser() {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() =>{
        async function findUser() {
            let axiosT = axios.create({
                baseURL: `${process.env.REACT_APP_FEATURED_API}auth/profile/`,
                headers: {
                  Authorization: `JWT ${localStorage.getItem("token")}`, // token has user data so /profile knows which (signed in) user to get
                },
            });

            await axiosT.get('/')
            .then(res => {
                let currentUser = res.data.currentUser
                setUser(currentUser);
                setLoading(false);
            }).catch(err => {
                console.log("No currently signed in user, redirecting to login");
                setLoading(false);
            });
        }
        findUser();  
    }, []);
    
    return {
        user,
        setUser,
        isLoading
    }
}