import styled from "styled-components";
import { Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export const Layout = styled.div`
    display: grid;
    background-color: #e5e5e5;
    grid-template-columns: 1fr 15fr;
    grid-template-areas: "aa bb";
    min-height: 1000px;
    max-height: auto;
`;
export const SidebarWrap = styled.div`
    grid-area: aa;
    height: auto 100vh;
`;

export const DetailView = styled.div`
    display: grid;
    grid-area: bb;
    background-color: white;
    min-width: 200px;
    box-sizing: border-box;
    grid-template-columns: 7fr 5fr;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto;

    //grid-template-areas: "xx yy";

    /* grid-gap: 30px;
    justify-items: center;
    align-content: center;
    padding: 40px; */

    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
    }
`;
export const Bar1 = styled.div`
    //grid-area: xx;
    grid-column:1 / span 2;
    margin: 10px;
    margin-bottom: 0;
    font-size: 25px;
    overflow-y: hidden;
    min-height: 200px;
    max-height: none;
`;

export const Bar2 = styled.div`
    //grid-area: xx;
    display: flex;
    flex-direction: column;

    background-color: white;
    border-color: black;
    font-size: 25px;
    margin: 10px;
    justify-self: stretch;

    overflow-y: hidden;
    min-height: 200px;
    max-height: auto;
`;

export const OpenModel = styled.img`
    height: 25px;
    width: 25px;
`;
export const Headline = styled.p`
    margin-left: 30px;
    padding-left: 10px;
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
`;
export const Para = styled.p`
    margin-left: 30px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 15px;
    padding-top: 5px;
    margin-block-start: 0px;
    margin-block-end: 0px;

    .floatLeft {
        float:left;
    }
    .floatRight {
        float:right;
    }
    white-space:normal;
`;

export const TipSheetBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-color: black;
    font-size: 20px;
    justify-self: stretch;
    // overflow-y: hidden;
    max-height: none;
    margin: 20px;
    padding-right: 20px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
`;

export const Bar3 = styled.div`
    //grid-area: yy;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-color: black;
    font-size: 25px;
    justify-self: stretch;
    overflow-y: hidden;
    min-height: 200px;
    max-height: auto;
`;
export const VideoSlider = styled(Slider)`
    width: 450px;
    max-width: 100%;
    text-align: center;
    @media (max-width: 700px) {
        width: 300px;
    }
`;
export const Dots = styled(({ className, children }) => (
    <span className={`dots ${className}`}>{children}</span>
))``;

export const VideoWrap = styled.div`
    /* grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end | itemname; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    borderColor: black;
    margin: 30px;

    font-size: 25px;
    grid-area: k;
    justify-self: stretch;
    border:1px solid #E5E5E5;
    border-radius: 10px;
`;
export const Video = styled.video`
    grid-area: k;
    height: auto;
    // height: 90%;
    cursor: pointer;
`;
export const BoxTitle = styled.h3`
    padding: 20px 20px 10px 20px;
    margin: 0;
    overflow: hidden;
    margin-left: 20px;
`;

export const BoxNote = styled.p`
    padding: 10px 20px 10px 20px;
    font-size: 12px;
    margin: 0;
    overflow: hidden;
    margin-left: 20px;
`;

export const BoxImage = styled.img`
    width: 60px;
    cursor: pointer;
    padding-inline: 23px;
`;

export const ImageWrap = styled.div`
    /* grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end | itemname; */
    display: grid;
    flex-direction: column;
    background-color: #ffffff;
    font-size: 25px;
    margin: 30px;
    grid-area: l;
    justify-self: stretch;
    border:1px solid #E5E5E5;
    border-radius: 10px;
`;
export const Images = styled.div`
    padding: 20px 20px 10px 20px;
    min-height: 245px;
    font-size: 12px;
    //text-align:center;
    justify-self: center;
    //cursor: pointer;
`;
export const ImageSliderWrap = styled.div`
    height: 100%;
    position: relative;
    display: grid;
    //align-items: center;
    //justify-items: center;
    place-items: center
`;
export const Cancel = styled.i`
    position: absolute;
    top: 30px;
    right: 30px;
`;
export const ImageSlideToggle = styled.i`
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
`;

export const Article = styled.div``;

export const  InterestTag = styled.div`
    float: right;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 12px;
    padding: 0 7px;
    margin: 16px 5px;
    display: grid;
    grid-template-columns: 20px auto;
`;

export const InterestTagIcon = styled.img`
    width:20px;
    margin-top:3px;
`;

export const InterestTagLabel = styled.div`
    float: right;
    padding: 5px;
    font-weight: bold;
`;


export const TipSummaryBlock = styled.div`
    display: inline-block;
`;


export const VideoHearingLink = styled.div`
    cursor: pointer;
    width: 40%;
    display: inline-block;
    //float: left;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 80px auto;
`;

export const SummaryVideo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const VideoHearingIcon = styled.img`
    width: 75px
`;
export const VideoHearingLabel = styled.div`
    font-size : 15px;
    padding : 14px 8px;
    font-weight : 500;
`;

export const TranscriptBox = styled.div`
    overflow-y: scroll;
    height: 200px;
    border: 1px solid #aaa8a8;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
`;

export const VideoBlock = styled.div`
    padding: 3px;
    cursor: pointer;
`;


export const Block = styled.div`width:100%`;
export const Span = styled.span``;
export const VideoPlayer = styled.video`
    height: auto;
    width:100%;
    cursor: pointer;
`;


export const Caption = styled.span`
    font-size: 13px;
    padding-left: 20px;
`;